:root {
  --tl-width: 750px;
  --radius: 12px;
  --radius-round: 24px;
  --hover-color: rgba(170,170,170,0.07);
  --border-color: rgba(120,120,120,0.2);
  --shadow: 0 10px 40px -10px rgba(0,0,0,0.15);
  --shadow-low: 0 8px 16px -10px rgba(0,0,0,0.4);
  --shadow-med: 0 8px 60px -30px rgba(0,0,0,0.1);
  --status-left-padding: 75px;
}
.rtl textarea,
.rtl p {
  text-align: right;
  direction: rtl;
}
#mastodon .button--block,
.public-layout .button--block,
.modal-layout .button--block {
  font-weight: 700;
}
#mastodon .compose-form__upload-thumbnail,
.public-layout .compose-form__upload-thumbnail,
.modal-layout .compose-form__upload-thumbnail,
#mastodon button:not(.column-header__button),
.public-layout button:not(.column-header__button),
.modal-layout button:not(.column-header__button),
#mastodon video,
.public-layout video,
.modal-layout video,
#mastodon input,
.public-layout input,
.modal-layout input,
#mastodon iframe,
.public-layout iframe,
.modal-layout iframe,
#mastodon .privacy-dropdown__dropdown,
.public-layout .privacy-dropdown__dropdown,
.modal-layout .privacy-dropdown__dropdown,
#mastodon .react-toggle-track,
.public-layout .react-toggle-track,
.modal-layout .react-toggle-track,
#mastodon .reply-indicator,
.public-layout .reply-indicator,
.modal-layout .reply-indicator,
#mastodon .compose-form__warning,
.public-layout .compose-form__warning,
.modal-layout .compose-form__warning {
  border-radius: var(--radius) !important;
}
#mastodon button:focus-visible,
.public-layout button:focus-visible,
.modal-layout button:focus-visible,
#mastodon .focusable:focus-visible,
.public-layout .focusable:focus-visible,
.modal-layout .focusable:focus-visible,
#mastodon a:focus-visible,
.public-layout a:focus-visible,
.modal-layout a:focus-visible,
#mastodon .media-gallery__item-thumbnail:focus-visible,
.public-layout .media-gallery__item-thumbnail:focus-visible,
.modal-layout .media-gallery__item-thumbnail:focus-visible {
  box-shadow: inset 0 0 0 2px #dc7b18;
  outline: 2px #dc7b18 solid;
  outline-offset: -2px;
}
#mastodon *,
.public-layout *,
.modal-layout * {
  border-color: var(--border-color) !important;
  font-display: swap !important;
}
#mastodon .load-more,
.public-layout .load-more,
.modal-layout .load-more,
#mastodon .trends__item,
.public-layout .trends__item,
.modal-layout .trends__item,
#mastodon .story,
.public-layout .story,
.modal-layout .story,
#mastodon .media-gallery__item,
.public-layout .media-gallery__item,
.modal-layout .media-gallery__item,
#mastodon .column-link,
.public-layout .column-link,
.modal-layout .column-link,
#mastodon select,
.public-layout select,
.modal-layout select,
#mastodon .status-card,
.public-layout .status-card,
.modal-layout .status-card,
#mastodon .audio-player,
.public-layout .audio-player,
.modal-layout .audio-player,
#mastodon .account,
.public-layout .account,
.modal-layout .account {
  transition: transform 0.4s cubic-bezier(0, 0, 0, 3) !important;
}
#mastodon .load-more:active,
.public-layout .load-more:active,
.modal-layout .load-more:active,
#mastodon .trends__item:active,
.public-layout .trends__item:active,
.modal-layout .trends__item:active,
#mastodon .story:active,
.public-layout .story:active,
.modal-layout .story:active,
#mastodon .media-gallery__item:active,
.public-layout .media-gallery__item:active,
.modal-layout .media-gallery__item:active,
#mastodon .column-link:active,
.public-layout .column-link:active,
.modal-layout .column-link:active,
#mastodon select:active,
.public-layout select:active,
.modal-layout select:active,
#mastodon .status-card:active,
.public-layout .status-card:active,
.modal-layout .status-card:active,
#mastodon .audio-player:active,
.public-layout .audio-player:active,
.modal-layout .audio-player:active,
#mastodon .account:active,
.public-layout .account:active,
.modal-layout .account:active {
  transform: scale(0.99);
  transition: transform 0.4s cubic-bezier(0, 0, 0, 1) !important;
}
#mastodon .react-toggle-track,
.public-layout .react-toggle-track,
.modal-layout .react-toggle-track,
#mastodon .button,
.public-layout .button,
.modal-layout .button,
#mastodon .icon-button,
.public-layout .icon-button,
.modal-layout .icon-button,
#mastodon .floating-action-button,
.public-layout .floating-action-button,
.modal-layout .floating-action-button {
  transition: transform 0.4s cubic-bezier(0, 0, 0, 4) !important;
}
#mastodon .react-toggle-track:active,
.public-layout .react-toggle-track:active,
.modal-layout .react-toggle-track:active,
#mastodon .button:active,
.public-layout .button:active,
.modal-layout .button:active,
#mastodon .icon-button:active,
.public-layout .icon-button:active,
.modal-layout .icon-button:active,
#mastodon .floating-action-button:active,
.public-layout .floating-action-button:active,
.modal-layout .floating-action-button:active {
  transform: scale(0.95);
  transition: transform 0.4s cubic-bezier(0, 0, 0, 1) !important;
}
#mastodon .status__content__spoiler-link span,
.public-layout .status__content__spoiler-link span,
.modal-layout .status__content__spoiler-link span {
  display: inline-block;
  transition: transform 0.4s cubic-bezier(0, 0, 0, 4) !important;
}
#mastodon .status__content__spoiler-link:active span,
.public-layout .status__content__spoiler-link:active span,
.modal-layout .status__content__spoiler-link:active span {
  transition: transform 0.4s cubic-bezier(0, 0, 0, 1) !important;
  transform: translateY(1px);
}
#mastodon .react-toggle-thumb,
.public-layout .react-toggle-thumb,
.modal-layout .react-toggle-thumb {
  pointer-events: none;
}
#mastodon .account__avatar,
.public-layout .account__avatar,
.modal-layout .account__avatar,
#mastodon #profile_page_avatar,
.public-layout #profile_page_avatar,
.modal-layout #profile_page_avatar,
#mastodon .account__avatar-composite,
.public-layout .account__avatar-composite,
.modal-layout .account__avatar-composite,
#mastodon .account-card__title__avatar img,
.public-layout .account-card__title__avatar img,
.modal-layout .account-card__title__avatar img {
  border-radius: 30% !important;
}
#mastodon .account,
.public-layout .account,
.modal-layout .account,
#mastodon .detailed-status__action-bar,
.public-layout .detailed-status__action-bar,
.modal-layout .detailed-status__action-bar,
#mastodon .column-header__collapsible-inner,
.public-layout .column-header__collapsible-inner,
.modal-layout .column-header__collapsible-inner,
#mastodon .compose-form__buttons-wrapper,
.public-layout .compose-form__buttons-wrapper,
.modal-layout .compose-form__buttons-wrapper,
#mastodon .audio-player,
.public-layout .audio-player,
.modal-layout .audio-player,
#mastodon .box-widget input[type="email"],
.public-layout .box-widget input[type="email"],
.modal-layout .box-widget input[type="email"],
#mastodon .box-widget input[type="password"],
.public-layout .box-widget input[type="password"],
.modal-layout .box-widget input[type="password"],
#mastodon .box-widget input[type="text"],
.public-layout .box-widget input[type="text"],
.modal-layout .box-widget input[type="text"],
#mastodon .box-widget textarea,
.public-layout .box-widget textarea,
.modal-layout .box-widget textarea,
#mastodon .compose-form .spoiler-input__input,
.public-layout .compose-form .spoiler-input__input,
.modal-layout .compose-form .spoiler-input__input,
#mastodon .compose-form__autosuggest-wrapper,
.public-layout .compose-form__autosuggest-wrapper,
.modal-layout .compose-form__autosuggest-wrapper,
#mastodon .compose-form__poll-wrapper select,
.public-layout .compose-form__poll-wrapper select,
.modal-layout .compose-form__poll-wrapper select,
#mastodon .poll__option input[type="text"],
.public-layout .poll__option input[type="text"],
.modal-layout .poll__option input[type="text"],
#mastodon .report-dialog-modal__textarea,
.public-layout .report-dialog-modal__textarea,
.modal-layout .report-dialog-modal__textarea,
#mastodon .search__input,
.public-layout .search__input,
.modal-layout .search__input,
#mastodon .setting-text,
.public-layout .setting-text,
.modal-layout .setting-text,
#mastodon .statuses-grid .detailed-status,
.public-layout .statuses-grid .detailed-status,
.modal-layout .statuses-grid .detailed-status {
  border: 0 !important;
}
#mastodon .columns-area__panels__pane__inner,
.public-layout .columns-area__panels__pane__inner,
.modal-layout .columns-area__panels__pane__inner {
  margin-top: 30px;
}
#mastodon .compose-panel,
.public-layout .compose-panel,
.modal-layout .compose-panel {
  margin-top: 0;
}
#mastodon .search,
.public-layout .search,
.modal-layout .search,
.search-popout {
  margin-inline: 5px;
  border-radius: var(--radius);
}
#mastodon .search label,
.public-layout .search label,
.modal-layout .search label {
  box-sizing: border-box;
}
#mastodon .search input,
.public-layout .search input,
.modal-layout .search input {
  border-radius: var(--radius-round) !important;
  padding-inline-start: 20px !important;
  padding-inline-end: 40px !important;
}
#mastodon .search .search__icon > i,
.public-layout .search .search__icon > i,
.modal-layout .search .search__icon > i {
  margin-inline: 5px;
}
#mastodon .compose__action-bar-dropdown .icon-button,
.public-layout .compose__action-bar-dropdown .icon-button,
.modal-layout .compose__action-bar-dropdown .icon-button {
  width: auto !important;
  height: auto !important;
  padding: 8px;
}
#mastodon .compose-form .reply-indicator__display-name,
.public-layout .compose-form .reply-indicator__display-name,
.modal-layout .compose-form .reply-indicator__display-name {
  padding: 0;
}
#mastodon .compose-form .compose-form__autosuggest-wrapper,
.public-layout .compose-form .compose-form__autosuggest-wrapper,
.modal-layout .compose-form .compose-form__autosuggest-wrapper,
#mastodon .compose-form .autosuggest-textarea__textarea,
.public-layout .compose-form .autosuggest-textarea__textarea,
.modal-layout .compose-form .autosuggest-textarea__textarea {
  border-radius: var(--radius) var(--radius) 0 0 !important;
}
#mastodon .compose-form .compose-form__buttons-wrapper,
.public-layout .compose-form .compose-form__buttons-wrapper,
.modal-layout .compose-form .compose-form__buttons-wrapper {
  border-radius: 0 0 var(--radius) var(--radius) !important;
}
#mastodon .compose-form .compose-form__publish-button-wrapper,
.public-layout .compose-form .compose-form__publish-button-wrapper,
.modal-layout .compose-form .compose-form__publish-button-wrapper {
  overflow: visible !important;
  max-width: 100%;
}
@media (min-width: 895px) {
  .layout-single-column .getting-started__footer {
    opacity: 0.5;
    transition: opacity 0.4s;
  }
  .layout-single-column .getting-started__footer a {
    text-decoration: none;
  }
  .layout-single-column .getting-started__footer > p {
    font-size: 0;
    line-height: 0;
    opacity: 0;
    transition: font-size 0.2s, line-height 0.3s cubic-bezier(0.5, 0, 0, 0);
    transition-duration: 0.2s;
  }
  .layout-single-column .getting-started__footer:hover,
  .layout-single-column .getting-started__footer:focus-within {
    opacity: 1;
  }
  .layout-single-column .getting-started__footer:hover > p,
  .layout-single-column .getting-started__footer:focus-within > p {
    font-size: 1em;
    line-height: 1.5;
    opacity: 1;
    transform: translateY(0px);
    transition: font-size 0.1s, line-height 0.3s cubic-bezier(0, 0, 0, 1.2), opacity 0.2s 0.1s;
  }
}
#mastodon .columns-area__panels__main,
.public-layout .columns-area__panels__main,
.modal-layout .columns-area__panels__main {
  margin: 0 10px;
  padding-top: 30px !important;
  overflow: visible !important;
  transition: max-width 0.2s cubic-bezier(0, 0, 0, 1.1), margin 0.2s cubic-bezier(0, 0, 0, 1.1);
}
@media (min-width: 1320px) {
  #mastodon .columns-area__panels__main,
  .public-layout .columns-area__panels__main,
  .modal-layout .columns-area__panels__main {
    max-width: var(--tl-width) !important;
    padding: 0 15px;
  }
}
#mastodon .columns-area__panels__main > div,
.public-layout .columns-area__panels__main > div,
.modal-layout .columns-area__panels__main > div {
  border-radius: var(--radius);
}
#mastodon .columns-area__panels__main > div:last-child,
.public-layout .columns-area__panels__main > div:last-child,
.modal-layout .columns-area__panels__main > div:last-child {
  box-shadow: var(--shadow-med);
}
#mastodon .columns-area__panels__main :not(.scrollable--flex) > .scrollable,
.public-layout .columns-area__panels__main :not(.scrollable--flex) > .scrollable,
.modal-layout .columns-area__panels__main :not(.scrollable--flex) > .scrollable {
  padding-bottom: 40vh !important;
}
#mastodon .scrollable,
.public-layout .scrollable,
.modal-layout .scrollable,
#mastodon .column-back-button,
.public-layout .column-back-button,
.modal-layout .column-back-button,
#mastodon .column-header,
.public-layout .column-header,
.modal-layout .column-header {
  border: 0;
  border-radius: var(--radius);
}
#mastodon .scrollable:not(.scrollable--flex),
.public-layout .scrollable:not(.scrollable--flex),
.modal-layout .scrollable:not(.scrollable--flex),
#mastodon .activity-stream,
.public-layout .activity-stream,
.modal-layout .activity-stream {
  border: 0 !important;
  contain: unset !important;
}
#mastodon .scrollable:not(.scrollable--flex):not(.activity-stream),
.public-layout .scrollable:not(.scrollable--flex):not(.activity-stream),
.modal-layout .scrollable:not(.scrollable--flex):not(.activity-stream),
#mastodon .activity-stream:not(.activity-stream),
.public-layout .activity-stream:not(.activity-stream),
.modal-layout .activity-stream:not(.activity-stream) {
  padding: 10px;
}
#mastodon .scrollable:not(.scrollable--flex) > [tabindex]:first-child > .focusable,
.public-layout .scrollable:not(.scrollable--flex) > [tabindex]:first-child > .focusable,
.modal-layout .scrollable:not(.scrollable--flex) > [tabindex]:first-child > .focusable,
#mastodon .activity-stream > [tabindex]:first-child > .focusable,
.public-layout .activity-stream > [tabindex]:first-child > .focusable,
.modal-layout .activity-stream > [tabindex]:first-child > .focusable {
  margin-top: 0;
}
#mastodon .scrollable:not(.scrollable--flex) > div > [tabindex]:last-child .status,
.public-layout .scrollable:not(.scrollable--flex) > div > [tabindex]:last-child .status,
.modal-layout .scrollable:not(.scrollable--flex) > div > [tabindex]:last-child .status,
#mastodon .activity-stream > div > [tabindex]:last-child .status,
.public-layout .activity-stream > div > [tabindex]:last-child .status,
.modal-layout .activity-stream > div > [tabindex]:last-child .status {
  border: 0;
}
#mastodon .columns-area,
.public-layout .columns-area,
.modal-layout .columns-area {
  box-sizing: border-box;
  padding-bottom: 20px;
  height: 100% !important;
}
#mastodon .column,
.public-layout .column,
.modal-layout .column,
#mastodon .columns-area,
.public-layout .columns-area,
.modal-layout .columns-area {
  overflow: visible !important;
}
#mastodon .explore__search-results,
.public-layout .explore__search-results,
.modal-layout .explore__search-results {
  padding: 10px;
}
#mastodon .scrollable--flex .account-timeline__header,
.public-layout .scrollable--flex .account-timeline__header,
.modal-layout .scrollable--flex .account-timeline__header {
  margin: 0 !important;
}
#mastodon .item-list,
.public-layout .item-list,
.modal-layout .item-list {
  background-color: inherit;
  border-radius: var(--radius);
}
#mastodon .account-timeline__header,
.public-layout .account-timeline__header,
.modal-layout .account-timeline__header {
  margin: -10px;
  margin-bottom: 10px;
  background-color: inherit;
  border-radius: var(--radius);
}
#mastodon .account-timeline__header .account__moved-note,
.public-layout .account-timeline__header .account__moved-note,
.modal-layout .account-timeline__header .account__moved-note {
  box-sizing: border-box;
  border-radius: var(--radius);
  margin-bottom: calc(0px - var(--radius));
  padding: 30px;
  padding-bottom: calc(var(--radius) + 30px);
  background: inherit;
}
#mastodon .account-timeline__header .account__moved-note .detailed-status__display-name,
.public-layout .account-timeline__header .account__moved-note .detailed-status__display-name,
.modal-layout .account-timeline__header .account__moved-note .detailed-status__display-name {
  overflow: visible !important;
}
#mastodon .account-timeline__header .account__header,
.public-layout .account-timeline__header .account__header,
.modal-layout .account-timeline__header .account__header {
  overflow: visible !important;
}
#mastodon .account-timeline__header .account__header__info,
.public-layout .account-timeline__header .account__header__info,
.modal-layout .account-timeline__header .account__header__info {
  z-index: 2;
}
#mastodon .account-timeline__header .account__header__image,
.public-layout .account-timeline__header .account__header__image,
.modal-layout .account-timeline__header .account__header__image {
  height: 0;
  padding-bottom: 35%;
  position: sticky;
  top: calc(0px - var(--radius));
  border-radius: var(--radius) var(--radius) 0 0;
}
#mastodon .account-timeline__header .account__header__image img,
.public-layout .account-timeline__header .account__header__image img,
.modal-layout .account-timeline__header .account__header__image img {
  position: absolute;
}
#mastodon .account-timeline__header .account__header__bar,
.public-layout .account-timeline__header .account__header__bar,
.modal-layout .account-timeline__header .account__header__bar {
  padding: 10px;
  border: 0;
  border-radius: var(--radius) var(--radius) 0 0;
  margin-top: calc(0px - var(--radius)) !important;
}
#mastodon .account-timeline__header .account__header__tabs__name h1,
.public-layout .account-timeline__header .account__header__tabs__name h1,
.modal-layout .account-timeline__header .account__header__tabs__name h1 {
  display: flex;
  flex-wrap: wrap;
}
#mastodon .account-timeline__header .account__header__tabs__name h1 span,
.public-layout .account-timeline__header .account__header__tabs__name h1 span,
.modal-layout .account-timeline__header .account__header__tabs__name h1 span {
  margin-right: 0.4em;
}
#mastodon .account-timeline__header .account-role,
.public-layout .account-timeline__header .account-role,
.modal-layout .account-timeline__header .account-role {
  margin-right: 0.4em;
}
#mastodon .account-timeline__header .account-role > span,
.public-layout .account-timeline__header .account-role > span,
.modal-layout .account-timeline__header .account-role > span {
  margin: 0 !important;
}
#mastodon .account-timeline__header .account__header__bio,
.public-layout .account-timeline__header .account__header__bio,
.modal-layout .account-timeline__header .account__header__bio {
  margin: 0;
  margin-top: 10px;
}
#mastodon .account-timeline__header .account__header__bio > div,
.public-layout .account-timeline__header .account__header__bio > div,
.modal-layout .account-timeline__header .account__header__bio > div {
  padding-inline: 10px !important;
}
#mastodon .account__header__fields,
.public-layout .account__header__fields,
.modal-layout .account__header__fields,
#mastodon .account__header__account-note,
.public-layout .account__header__account-note,
.modal-layout .account__header__account-note {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border: 0;
  margin-top: 0 !important;
  margin-bottom: 10px !important;
  gap: 10px;
}
.public-account-bio .account__header__fields {
  padding: 20px !important;
  padding-bottom: 0 !important;
}
#mastodon .account__header__fields dl,
.public-layout .account__header__fields dl,
.modal-layout .account__header__fields dl,
#mastodon .account__header__account-note dl,
.public-layout .account__header__account-note dl,
.modal-layout .account__header__account-note dl,
#mastodon .account__header__fields.account__header__account-note.account__header__account-note,
.public-layout .account__header__fields.account__header__account-note.account__header__account-note,
.modal-layout .account__header__fields.account__header__account-note.account__header__account-note,
#mastodon .account__header__account-note.account__header__account-note.account__header__account-note,
.public-layout .account__header__account-note.account__header__account-note.account__header__account-note,
.modal-layout .account__header__account-note.account__header__account-note.account__header__account-note {
  border: 0;
  border-radius: var(--radius-round);
  overflow: hidden;
  background-color: var(--border-color);
  padding: 0 !important;
}
#mastodon .account__header__fields dl dt,
.public-layout .account__header__fields dl dt,
.modal-layout .account__header__fields dl dt,
#mastodon .account__header__account-note dl dt,
.public-layout .account__header__account-note dl dt,
.modal-layout .account__header__account-note dl dt,
#mastodon .account__header__fields.account__header__account-note.account__header__account-note dt,
.public-layout .account__header__fields.account__header__account-note.account__header__account-note dt,
.modal-layout .account__header__fields.account__header__account-note.account__header__account-note dt,
#mastodon .account__header__account-note.account__header__account-note.account__header__account-note dt,
.public-layout .account__header__account-note.account__header__account-note.account__header__account-note dt,
.modal-layout .account__header__account-note.account__header__account-note.account__header__account-note dt,
#mastodon .account__header__fields dl dd,
.public-layout .account__header__fields dl dd,
.modal-layout .account__header__fields dl dd,
#mastodon .account__header__account-note dl dd,
.public-layout .account__header__account-note dl dd,
.modal-layout .account__header__account-note dl dd,
#mastodon .account__header__fields.account__header__account-note.account__header__account-note dd,
.public-layout .account__header__fields.account__header__account-note.account__header__account-note dd,
.modal-layout .account__header__fields.account__header__account-note.account__header__account-note dd,
#mastodon .account__header__account-note.account__header__account-note.account__header__account-note dd,
.public-layout .account__header__account-note.account__header__account-note.account__header__account-note dd,
.modal-layout .account__header__account-note.account__header__account-note.account__header__account-note dd {
  width: unset;
  padding-block: 0.5em;
}
#mastodon .account__header__fields dl dt,
.public-layout .account__header__fields dl dt,
.modal-layout .account__header__fields dl dt,
#mastodon .account__header__account-note dl dt,
.public-layout .account__header__account-note dl dt,
.modal-layout .account__header__account-note dl dt,
#mastodon .account__header__fields.account__header__account-note.account__header__account-note dt,
.public-layout .account__header__fields.account__header__account-note.account__header__account-note dt,
.modal-layout .account__header__fields.account__header__account-note.account__header__account-note dt,
#mastodon .account__header__account-note.account__header__account-note.account__header__account-note dt,
.public-layout .account__header__account-note.account__header__account-note.account__header__account-note dt,
.modal-layout .account__header__account-note.account__header__account-note.account__header__account-note dt {
  background: none;
}
#mastodon .account__header__fields dl dd,
.public-layout .account__header__fields dl dd,
.modal-layout .account__header__fields dl dd,
#mastodon .account__header__account-note dl dd,
.public-layout .account__header__account-note dl dd,
.modal-layout .account__header__account-note dl dd,
#mastodon .account__header__fields.account__header__account-note.account__header__account-note dd,
.public-layout .account__header__fields.account__header__account-note.account__header__account-note dd,
.modal-layout .account__header__fields.account__header__account-note.account__header__account-note dd,
#mastodon .account__header__account-note.account__header__account-note.account__header__account-note dd,
.public-layout .account__header__account-note.account__header__account-note.account__header__account-note dd,
.modal-layout .account__header__account-note.account__header__account-note.account__header__account-note dd {
  border: 0;
}
#mastodon .account__header__fields dl dd:not(.verified),
.public-layout .account__header__fields dl dd:not(.verified),
.modal-layout .account__header__fields dl dd:not(.verified),
#mastodon .account__header__account-note dl dd:not(.verified),
.public-layout .account__header__account-note dl dd:not(.verified),
.modal-layout .account__header__account-note dl dd:not(.verified),
#mastodon .account__header__fields.account__header__account-note.account__header__account-note dd:not(.verified),
.public-layout .account__header__fields.account__header__account-note.account__header__account-note dd:not(.verified),
.modal-layout .account__header__fields.account__header__account-note.account__header__account-note dd:not(.verified),
#mastodon .account__header__account-note.account__header__account-note.account__header__account-note dd:not(.verified),
.public-layout .account__header__account-note.account__header__account-note.account__header__account-note dd:not(.verified),
.modal-layout .account__header__account-note.account__header__account-note.account__header__account-note dd:not(.verified) {
  padding-inline-start: 0;
}
#mastodon .account__header__fields.account__header__account-note,
.public-layout .account__header__fields.account__header__account-note,
.modal-layout .account__header__fields.account__header__account-note,
#mastodon .account__header__account-note.account__header__account-note,
.public-layout .account__header__account-note.account__header__account-note,
.modal-layout .account__header__account-note.account__header__account-note {
  margin: 10px;
  margin-bottom: 0;
  gap: 0;
  background: none !important;
  border: 1px solid var(--border-color) !important;
}
#mastodon .account__header__fields textarea,
.public-layout .account__header__fields textarea,
.modal-layout .account__header__fields textarea,
#mastodon .account__header__account-note textarea,
.public-layout .account__header__account-note textarea,
.modal-layout .account__header__account-note textarea {
  width: 0;
  flex-grow: 1;
  margin: 0 !important;
  border-radius: 0;
}
#mastodon .account__header__fields label,
.public-layout .account__header__fields label,
.modal-layout .account__header__fields label,
#mastodon .account__header__account-note label,
.public-layout .account__header__account-note label,
.modal-layout .account__header__account-note label {
  margin: 0;
  font-weight: 600;
  padding-inline: 14px;
}
#mastodon .account__header__content,
.public-layout .account__header__content,
.modal-layout .account__header__content {
  padding-top: 10px;
}
#mastodon#mastodon .account__section-headline,
.public-layout#mastodon .account__section-headline,
.modal-layout#mastodon .account__section-headline {
  background: none;
}
.with-modals #mastodon#mastodon .account__section-headline,
.with-modals .public-layout#mastodon .account__section-headline,
.with-modals .modal-layout#mastodon .account__section-headline {
  border: 0 !important;
}
#mastodon#mastodon .account__section-headline a,
.public-layout#mastodon .account__section-headline a,
.modal-layout#mastodon .account__section-headline a,
#mastodon#mastodon .account__section-headline button,
.public-layout#mastodon .account__section-headline button,
.modal-layout#mastodon .account__section-headline button {
  background: none;
  border-radius: 0 !important;
}
#mastodon#mastodon .account__section-headline a span,
.public-layout#mastodon .account__section-headline a span,
.modal-layout#mastodon .account__section-headline a span,
#mastodon#mastodon .account__section-headline button span,
.public-layout#mastodon .account__section-headline button span,
.modal-layout#mastodon .account__section-headline button span {
  font-weight: 400;
}
#mastodon#mastodon .account__section-headline a.active span,
.public-layout#mastodon .account__section-headline a.active span,
.modal-layout#mastodon .account__section-headline a.active span,
#mastodon#mastodon .account__section-headline button.active span,
.public-layout#mastodon .account__section-headline button.active span,
.modal-layout#mastodon .account__section-headline button.active span {
  font-weight: 700;
}
#mastodon .notification__filter-bar,
.public-layout .notification__filter-bar,
.modal-layout .notification__filter-bar {
  border: 0 !important;
  margin-bottom: calc(0px - var(--radius));
  padding-bottom: var(--radius);
}
#mastodon .scrollable > div:first-child > [tabindex="-1"]:last-child .status__wrapper::after,
.public-layout .scrollable > div:first-child > [tabindex="-1"]:last-child .status__wrapper::after,
.modal-layout .scrollable > div:first-child > [tabindex="-1"]:last-child .status__wrapper::after {
  content: unset;
}
#mastodon .focusable,
.public-layout .focusable,
.modal-layout .focusable,
#mastodon .entry,
.public-layout .entry,
.modal-layout .entry,
#mastodon .statuses-grid__item .detailed-status,
.public-layout .statuses-grid__item .detailed-status,
.modal-layout .statuses-grid__item .detailed-status,
#mastodon .trends__item,
.public-layout .trends__item,
.modal-layout .trends__item,
#mastodon .story,
.public-layout .story,
.modal-layout .story,
#mastodon .account-card,
.public-layout .account-card,
.modal-layout .account-card,
#mastodon :not(.focusable) > .account,
.public-layout :not(.focusable) > .account,
.modal-layout :not(.focusable) > .account,
#mastodon .timeline-hint,
.public-layout .timeline-hint,
.modal-layout .timeline-hint {
  overflow: clip;
  border: 0 !important;
  border-radius: var(--radius) !important;
  transition: background 0.2s, box-shadow 0.4s, margin 0.2s, border 0.2s;
  animation: slideUp backwards 0.4s cubic-bezier(0, 1, 1, 1);
  position: relative;
}
#mastodon .focusable.trends__item,
.public-layout .focusable.trends__item,
.modal-layout .focusable.trends__item,
#mastodon .entry.trends__item,
.public-layout .entry.trends__item,
.modal-layout .entry.trends__item,
#mastodon .statuses-grid__item .detailed-status.trends__item,
.public-layout .statuses-grid__item .detailed-status.trends__item,
.modal-layout .statuses-grid__item .detailed-status.trends__item,
#mastodon .trends__item.trends__item,
.public-layout .trends__item.trends__item,
.modal-layout .trends__item.trends__item,
#mastodon .story.trends__item,
.public-layout .story.trends__item,
.modal-layout .story.trends__item,
#mastodon .account-card.trends__item,
.public-layout .account-card.trends__item,
.modal-layout .account-card.trends__item,
#mastodon :not(.focusable) > .account.trends__item,
.public-layout :not(.focusable) > .account.trends__item,
.modal-layout :not(.focusable) > .account.trends__item,
#mastodon .timeline-hint.trends__item,
.public-layout .timeline-hint.trends__item,
.modal-layout .timeline-hint.trends__item,
#mastodon .focusable.story,
.public-layout .focusable.story,
.modal-layout .focusable.story,
#mastodon .entry.story,
.public-layout .entry.story,
.modal-layout .entry.story,
#mastodon .statuses-grid__item .detailed-status.story,
.public-layout .statuses-grid__item .detailed-status.story,
.modal-layout .statuses-grid__item .detailed-status.story,
#mastodon .trends__item.story,
.public-layout .trends__item.story,
.modal-layout .trends__item.story,
#mastodon .story.story,
.public-layout .story.story,
.modal-layout .story.story,
#mastodon .account-card.story,
.public-layout .account-card.story,
.modal-layout .account-card.story,
#mastodon :not(.focusable) > .account.story,
.public-layout :not(.focusable) > .account.story,
.modal-layout :not(.focusable) > .account.story,
#mastodon .timeline-hint.story,
.public-layout .timeline-hint.story,
.modal-layout .timeline-hint.story,
#mastodon .focusable.account-card,
.public-layout .focusable.account-card,
.modal-layout .focusable.account-card,
#mastodon .entry.account-card,
.public-layout .entry.account-card,
.modal-layout .entry.account-card,
#mastodon .statuses-grid__item .detailed-status.account-card,
.public-layout .statuses-grid__item .detailed-status.account-card,
.modal-layout .statuses-grid__item .detailed-status.account-card,
#mastodon .trends__item.account-card,
.public-layout .trends__item.account-card,
.modal-layout .trends__item.account-card,
#mastodon .story.account-card,
.public-layout .story.account-card,
.modal-layout .story.account-card,
#mastodon .account-card.account-card,
.public-layout .account-card.account-card,
.modal-layout .account-card.account-card,
#mastodon :not(.focusable) > .account.account-card,
.public-layout :not(.focusable) > .account.account-card,
.modal-layout :not(.focusable) > .account.account-card,
#mastodon .timeline-hint.account-card,
.public-layout .timeline-hint.account-card,
.modal-layout .timeline-hint.account-card {
  animation-name: slideUpFade;
}
#mastodon .focusable.trends__item:nth-child(1),
.public-layout .focusable.trends__item:nth-child(1),
.modal-layout .focusable.trends__item:nth-child(1),
#mastodon .entry.trends__item:nth-child(1),
.public-layout .entry.trends__item:nth-child(1),
.modal-layout .entry.trends__item:nth-child(1),
#mastodon .statuses-grid__item .detailed-status.trends__item:nth-child(1),
.public-layout .statuses-grid__item .detailed-status.trends__item:nth-child(1),
.modal-layout .statuses-grid__item .detailed-status.trends__item:nth-child(1),
#mastodon .trends__item.trends__item:nth-child(1),
.public-layout .trends__item.trends__item:nth-child(1),
.modal-layout .trends__item.trends__item:nth-child(1),
#mastodon .story.trends__item:nth-child(1),
.public-layout .story.trends__item:nth-child(1),
.modal-layout .story.trends__item:nth-child(1),
#mastodon .account-card.trends__item:nth-child(1),
.public-layout .account-card.trends__item:nth-child(1),
.modal-layout .account-card.trends__item:nth-child(1),
#mastodon :not(.focusable) > .account.trends__item:nth-child(1),
.public-layout :not(.focusable) > .account.trends__item:nth-child(1),
.modal-layout :not(.focusable) > .account.trends__item:nth-child(1),
#mastodon .timeline-hint.trends__item:nth-child(1),
.public-layout .timeline-hint.trends__item:nth-child(1),
.modal-layout .timeline-hint.trends__item:nth-child(1),
#mastodon .focusable.story:nth-child(1),
.public-layout .focusable.story:nth-child(1),
.modal-layout .focusable.story:nth-child(1),
#mastodon .entry.story:nth-child(1),
.public-layout .entry.story:nth-child(1),
.modal-layout .entry.story:nth-child(1),
#mastodon .statuses-grid__item .detailed-status.story:nth-child(1),
.public-layout .statuses-grid__item .detailed-status.story:nth-child(1),
.modal-layout .statuses-grid__item .detailed-status.story:nth-child(1),
#mastodon .trends__item.story:nth-child(1),
.public-layout .trends__item.story:nth-child(1),
.modal-layout .trends__item.story:nth-child(1),
#mastodon .story.story:nth-child(1),
.public-layout .story.story:nth-child(1),
.modal-layout .story.story:nth-child(1),
#mastodon .account-card.story:nth-child(1),
.public-layout .account-card.story:nth-child(1),
.modal-layout .account-card.story:nth-child(1),
#mastodon :not(.focusable) > .account.story:nth-child(1),
.public-layout :not(.focusable) > .account.story:nth-child(1),
.modal-layout :not(.focusable) > .account.story:nth-child(1),
#mastodon .timeline-hint.story:nth-child(1),
.public-layout .timeline-hint.story:nth-child(1),
.modal-layout .timeline-hint.story:nth-child(1),
#mastodon .focusable.account-card:nth-child(1),
.public-layout .focusable.account-card:nth-child(1),
.modal-layout .focusable.account-card:nth-child(1),
#mastodon .entry.account-card:nth-child(1),
.public-layout .entry.account-card:nth-child(1),
.modal-layout .entry.account-card:nth-child(1),
#mastodon .statuses-grid__item .detailed-status.account-card:nth-child(1),
.public-layout .statuses-grid__item .detailed-status.account-card:nth-child(1),
.modal-layout .statuses-grid__item .detailed-status.account-card:nth-child(1),
#mastodon .trends__item.account-card:nth-child(1),
.public-layout .trends__item.account-card:nth-child(1),
.modal-layout .trends__item.account-card:nth-child(1),
#mastodon .story.account-card:nth-child(1),
.public-layout .story.account-card:nth-child(1),
.modal-layout .story.account-card:nth-child(1),
#mastodon .account-card.account-card:nth-child(1),
.public-layout .account-card.account-card:nth-child(1),
.modal-layout .account-card.account-card:nth-child(1),
#mastodon :not(.focusable) > .account.account-card:nth-child(1),
.public-layout :not(.focusable) > .account.account-card:nth-child(1),
.modal-layout :not(.focusable) > .account.account-card:nth-child(1),
#mastodon .timeline-hint.account-card:nth-child(1),
.public-layout .timeline-hint.account-card:nth-child(1),
.modal-layout .timeline-hint.account-card:nth-child(1) {
  animation-delay: 0.04s;
}
#mastodon .focusable.trends__item:nth-child(2),
.public-layout .focusable.trends__item:nth-child(2),
.modal-layout .focusable.trends__item:nth-child(2),
#mastodon .entry.trends__item:nth-child(2),
.public-layout .entry.trends__item:nth-child(2),
.modal-layout .entry.trends__item:nth-child(2),
#mastodon .statuses-grid__item .detailed-status.trends__item:nth-child(2),
.public-layout .statuses-grid__item .detailed-status.trends__item:nth-child(2),
.modal-layout .statuses-grid__item .detailed-status.trends__item:nth-child(2),
#mastodon .trends__item.trends__item:nth-child(2),
.public-layout .trends__item.trends__item:nth-child(2),
.modal-layout .trends__item.trends__item:nth-child(2),
#mastodon .story.trends__item:nth-child(2),
.public-layout .story.trends__item:nth-child(2),
.modal-layout .story.trends__item:nth-child(2),
#mastodon .account-card.trends__item:nth-child(2),
.public-layout .account-card.trends__item:nth-child(2),
.modal-layout .account-card.trends__item:nth-child(2),
#mastodon :not(.focusable) > .account.trends__item:nth-child(2),
.public-layout :not(.focusable) > .account.trends__item:nth-child(2),
.modal-layout :not(.focusable) > .account.trends__item:nth-child(2),
#mastodon .timeline-hint.trends__item:nth-child(2),
.public-layout .timeline-hint.trends__item:nth-child(2),
.modal-layout .timeline-hint.trends__item:nth-child(2),
#mastodon .focusable.story:nth-child(2),
.public-layout .focusable.story:nth-child(2),
.modal-layout .focusable.story:nth-child(2),
#mastodon .entry.story:nth-child(2),
.public-layout .entry.story:nth-child(2),
.modal-layout .entry.story:nth-child(2),
#mastodon .statuses-grid__item .detailed-status.story:nth-child(2),
.public-layout .statuses-grid__item .detailed-status.story:nth-child(2),
.modal-layout .statuses-grid__item .detailed-status.story:nth-child(2),
#mastodon .trends__item.story:nth-child(2),
.public-layout .trends__item.story:nth-child(2),
.modal-layout .trends__item.story:nth-child(2),
#mastodon .story.story:nth-child(2),
.public-layout .story.story:nth-child(2),
.modal-layout .story.story:nth-child(2),
#mastodon .account-card.story:nth-child(2),
.public-layout .account-card.story:nth-child(2),
.modal-layout .account-card.story:nth-child(2),
#mastodon :not(.focusable) > .account.story:nth-child(2),
.public-layout :not(.focusable) > .account.story:nth-child(2),
.modal-layout :not(.focusable) > .account.story:nth-child(2),
#mastodon .timeline-hint.story:nth-child(2),
.public-layout .timeline-hint.story:nth-child(2),
.modal-layout .timeline-hint.story:nth-child(2),
#mastodon .focusable.account-card:nth-child(2),
.public-layout .focusable.account-card:nth-child(2),
.modal-layout .focusable.account-card:nth-child(2),
#mastodon .entry.account-card:nth-child(2),
.public-layout .entry.account-card:nth-child(2),
.modal-layout .entry.account-card:nth-child(2),
#mastodon .statuses-grid__item .detailed-status.account-card:nth-child(2),
.public-layout .statuses-grid__item .detailed-status.account-card:nth-child(2),
.modal-layout .statuses-grid__item .detailed-status.account-card:nth-child(2),
#mastodon .trends__item.account-card:nth-child(2),
.public-layout .trends__item.account-card:nth-child(2),
.modal-layout .trends__item.account-card:nth-child(2),
#mastodon .story.account-card:nth-child(2),
.public-layout .story.account-card:nth-child(2),
.modal-layout .story.account-card:nth-child(2),
#mastodon .account-card.account-card:nth-child(2),
.public-layout .account-card.account-card:nth-child(2),
.modal-layout .account-card.account-card:nth-child(2),
#mastodon :not(.focusable) > .account.account-card:nth-child(2),
.public-layout :not(.focusable) > .account.account-card:nth-child(2),
.modal-layout :not(.focusable) > .account.account-card:nth-child(2),
#mastodon .timeline-hint.account-card:nth-child(2),
.public-layout .timeline-hint.account-card:nth-child(2),
.modal-layout .timeline-hint.account-card:nth-child(2) {
  animation-delay: 0.08s;
}
#mastodon .focusable.trends__item:nth-child(3),
.public-layout .focusable.trends__item:nth-child(3),
.modal-layout .focusable.trends__item:nth-child(3),
#mastodon .entry.trends__item:nth-child(3),
.public-layout .entry.trends__item:nth-child(3),
.modal-layout .entry.trends__item:nth-child(3),
#mastodon .statuses-grid__item .detailed-status.trends__item:nth-child(3),
.public-layout .statuses-grid__item .detailed-status.trends__item:nth-child(3),
.modal-layout .statuses-grid__item .detailed-status.trends__item:nth-child(3),
#mastodon .trends__item.trends__item:nth-child(3),
.public-layout .trends__item.trends__item:nth-child(3),
.modal-layout .trends__item.trends__item:nth-child(3),
#mastodon .story.trends__item:nth-child(3),
.public-layout .story.trends__item:nth-child(3),
.modal-layout .story.trends__item:nth-child(3),
#mastodon .account-card.trends__item:nth-child(3),
.public-layout .account-card.trends__item:nth-child(3),
.modal-layout .account-card.trends__item:nth-child(3),
#mastodon :not(.focusable) > .account.trends__item:nth-child(3),
.public-layout :not(.focusable) > .account.trends__item:nth-child(3),
.modal-layout :not(.focusable) > .account.trends__item:nth-child(3),
#mastodon .timeline-hint.trends__item:nth-child(3),
.public-layout .timeline-hint.trends__item:nth-child(3),
.modal-layout .timeline-hint.trends__item:nth-child(3),
#mastodon .focusable.story:nth-child(3),
.public-layout .focusable.story:nth-child(3),
.modal-layout .focusable.story:nth-child(3),
#mastodon .entry.story:nth-child(3),
.public-layout .entry.story:nth-child(3),
.modal-layout .entry.story:nth-child(3),
#mastodon .statuses-grid__item .detailed-status.story:nth-child(3),
.public-layout .statuses-grid__item .detailed-status.story:nth-child(3),
.modal-layout .statuses-grid__item .detailed-status.story:nth-child(3),
#mastodon .trends__item.story:nth-child(3),
.public-layout .trends__item.story:nth-child(3),
.modal-layout .trends__item.story:nth-child(3),
#mastodon .story.story:nth-child(3),
.public-layout .story.story:nth-child(3),
.modal-layout .story.story:nth-child(3),
#mastodon .account-card.story:nth-child(3),
.public-layout .account-card.story:nth-child(3),
.modal-layout .account-card.story:nth-child(3),
#mastodon :not(.focusable) > .account.story:nth-child(3),
.public-layout :not(.focusable) > .account.story:nth-child(3),
.modal-layout :not(.focusable) > .account.story:nth-child(3),
#mastodon .timeline-hint.story:nth-child(3),
.public-layout .timeline-hint.story:nth-child(3),
.modal-layout .timeline-hint.story:nth-child(3),
#mastodon .focusable.account-card:nth-child(3),
.public-layout .focusable.account-card:nth-child(3),
.modal-layout .focusable.account-card:nth-child(3),
#mastodon .entry.account-card:nth-child(3),
.public-layout .entry.account-card:nth-child(3),
.modal-layout .entry.account-card:nth-child(3),
#mastodon .statuses-grid__item .detailed-status.account-card:nth-child(3),
.public-layout .statuses-grid__item .detailed-status.account-card:nth-child(3),
.modal-layout .statuses-grid__item .detailed-status.account-card:nth-child(3),
#mastodon .trends__item.account-card:nth-child(3),
.public-layout .trends__item.account-card:nth-child(3),
.modal-layout .trends__item.account-card:nth-child(3),
#mastodon .story.account-card:nth-child(3),
.public-layout .story.account-card:nth-child(3),
.modal-layout .story.account-card:nth-child(3),
#mastodon .account-card.account-card:nth-child(3),
.public-layout .account-card.account-card:nth-child(3),
.modal-layout .account-card.account-card:nth-child(3),
#mastodon :not(.focusable) > .account.account-card:nth-child(3),
.public-layout :not(.focusable) > .account.account-card:nth-child(3),
.modal-layout :not(.focusable) > .account.account-card:nth-child(3),
#mastodon .timeline-hint.account-card:nth-child(3),
.public-layout .timeline-hint.account-card:nth-child(3),
.modal-layout .timeline-hint.account-card:nth-child(3) {
  animation-delay: 0.12s;
}
#mastodon .focusable.trends__item:nth-child(4),
.public-layout .focusable.trends__item:nth-child(4),
.modal-layout .focusable.trends__item:nth-child(4),
#mastodon .entry.trends__item:nth-child(4),
.public-layout .entry.trends__item:nth-child(4),
.modal-layout .entry.trends__item:nth-child(4),
#mastodon .statuses-grid__item .detailed-status.trends__item:nth-child(4),
.public-layout .statuses-grid__item .detailed-status.trends__item:nth-child(4),
.modal-layout .statuses-grid__item .detailed-status.trends__item:nth-child(4),
#mastodon .trends__item.trends__item:nth-child(4),
.public-layout .trends__item.trends__item:nth-child(4),
.modal-layout .trends__item.trends__item:nth-child(4),
#mastodon .story.trends__item:nth-child(4),
.public-layout .story.trends__item:nth-child(4),
.modal-layout .story.trends__item:nth-child(4),
#mastodon .account-card.trends__item:nth-child(4),
.public-layout .account-card.trends__item:nth-child(4),
.modal-layout .account-card.trends__item:nth-child(4),
#mastodon :not(.focusable) > .account.trends__item:nth-child(4),
.public-layout :not(.focusable) > .account.trends__item:nth-child(4),
.modal-layout :not(.focusable) > .account.trends__item:nth-child(4),
#mastodon .timeline-hint.trends__item:nth-child(4),
.public-layout .timeline-hint.trends__item:nth-child(4),
.modal-layout .timeline-hint.trends__item:nth-child(4),
#mastodon .focusable.story:nth-child(4),
.public-layout .focusable.story:nth-child(4),
.modal-layout .focusable.story:nth-child(4),
#mastodon .entry.story:nth-child(4),
.public-layout .entry.story:nth-child(4),
.modal-layout .entry.story:nth-child(4),
#mastodon .statuses-grid__item .detailed-status.story:nth-child(4),
.public-layout .statuses-grid__item .detailed-status.story:nth-child(4),
.modal-layout .statuses-grid__item .detailed-status.story:nth-child(4),
#mastodon .trends__item.story:nth-child(4),
.public-layout .trends__item.story:nth-child(4),
.modal-layout .trends__item.story:nth-child(4),
#mastodon .story.story:nth-child(4),
.public-layout .story.story:nth-child(4),
.modal-layout .story.story:nth-child(4),
#mastodon .account-card.story:nth-child(4),
.public-layout .account-card.story:nth-child(4),
.modal-layout .account-card.story:nth-child(4),
#mastodon :not(.focusable) > .account.story:nth-child(4),
.public-layout :not(.focusable) > .account.story:nth-child(4),
.modal-layout :not(.focusable) > .account.story:nth-child(4),
#mastodon .timeline-hint.story:nth-child(4),
.public-layout .timeline-hint.story:nth-child(4),
.modal-layout .timeline-hint.story:nth-child(4),
#mastodon .focusable.account-card:nth-child(4),
.public-layout .focusable.account-card:nth-child(4),
.modal-layout .focusable.account-card:nth-child(4),
#mastodon .entry.account-card:nth-child(4),
.public-layout .entry.account-card:nth-child(4),
.modal-layout .entry.account-card:nth-child(4),
#mastodon .statuses-grid__item .detailed-status.account-card:nth-child(4),
.public-layout .statuses-grid__item .detailed-status.account-card:nth-child(4),
.modal-layout .statuses-grid__item .detailed-status.account-card:nth-child(4),
#mastodon .trends__item.account-card:nth-child(4),
.public-layout .trends__item.account-card:nth-child(4),
.modal-layout .trends__item.account-card:nth-child(4),
#mastodon .story.account-card:nth-child(4),
.public-layout .story.account-card:nth-child(4),
.modal-layout .story.account-card:nth-child(4),
#mastodon .account-card.account-card:nth-child(4),
.public-layout .account-card.account-card:nth-child(4),
.modal-layout .account-card.account-card:nth-child(4),
#mastodon :not(.focusable) > .account.account-card:nth-child(4),
.public-layout :not(.focusable) > .account.account-card:nth-child(4),
.modal-layout :not(.focusable) > .account.account-card:nth-child(4),
#mastodon .timeline-hint.account-card:nth-child(4),
.public-layout .timeline-hint.account-card:nth-child(4),
.modal-layout .timeline-hint.account-card:nth-child(4) {
  animation-delay: 0.16s;
}
#mastodon .focusable.trends__item:nth-child(5),
.public-layout .focusable.trends__item:nth-child(5),
.modal-layout .focusable.trends__item:nth-child(5),
#mastodon .entry.trends__item:nth-child(5),
.public-layout .entry.trends__item:nth-child(5),
.modal-layout .entry.trends__item:nth-child(5),
#mastodon .statuses-grid__item .detailed-status.trends__item:nth-child(5),
.public-layout .statuses-grid__item .detailed-status.trends__item:nth-child(5),
.modal-layout .statuses-grid__item .detailed-status.trends__item:nth-child(5),
#mastodon .trends__item.trends__item:nth-child(5),
.public-layout .trends__item.trends__item:nth-child(5),
.modal-layout .trends__item.trends__item:nth-child(5),
#mastodon .story.trends__item:nth-child(5),
.public-layout .story.trends__item:nth-child(5),
.modal-layout .story.trends__item:nth-child(5),
#mastodon .account-card.trends__item:nth-child(5),
.public-layout .account-card.trends__item:nth-child(5),
.modal-layout .account-card.trends__item:nth-child(5),
#mastodon :not(.focusable) > .account.trends__item:nth-child(5),
.public-layout :not(.focusable) > .account.trends__item:nth-child(5),
.modal-layout :not(.focusable) > .account.trends__item:nth-child(5),
#mastodon .timeline-hint.trends__item:nth-child(5),
.public-layout .timeline-hint.trends__item:nth-child(5),
.modal-layout .timeline-hint.trends__item:nth-child(5),
#mastodon .focusable.story:nth-child(5),
.public-layout .focusable.story:nth-child(5),
.modal-layout .focusable.story:nth-child(5),
#mastodon .entry.story:nth-child(5),
.public-layout .entry.story:nth-child(5),
.modal-layout .entry.story:nth-child(5),
#mastodon .statuses-grid__item .detailed-status.story:nth-child(5),
.public-layout .statuses-grid__item .detailed-status.story:nth-child(5),
.modal-layout .statuses-grid__item .detailed-status.story:nth-child(5),
#mastodon .trends__item.story:nth-child(5),
.public-layout .trends__item.story:nth-child(5),
.modal-layout .trends__item.story:nth-child(5),
#mastodon .story.story:nth-child(5),
.public-layout .story.story:nth-child(5),
.modal-layout .story.story:nth-child(5),
#mastodon .account-card.story:nth-child(5),
.public-layout .account-card.story:nth-child(5),
.modal-layout .account-card.story:nth-child(5),
#mastodon :not(.focusable) > .account.story:nth-child(5),
.public-layout :not(.focusable) > .account.story:nth-child(5),
.modal-layout :not(.focusable) > .account.story:nth-child(5),
#mastodon .timeline-hint.story:nth-child(5),
.public-layout .timeline-hint.story:nth-child(5),
.modal-layout .timeline-hint.story:nth-child(5),
#mastodon .focusable.account-card:nth-child(5),
.public-layout .focusable.account-card:nth-child(5),
.modal-layout .focusable.account-card:nth-child(5),
#mastodon .entry.account-card:nth-child(5),
.public-layout .entry.account-card:nth-child(5),
.modal-layout .entry.account-card:nth-child(5),
#mastodon .statuses-grid__item .detailed-status.account-card:nth-child(5),
.public-layout .statuses-grid__item .detailed-status.account-card:nth-child(5),
.modal-layout .statuses-grid__item .detailed-status.account-card:nth-child(5),
#mastodon .trends__item.account-card:nth-child(5),
.public-layout .trends__item.account-card:nth-child(5),
.modal-layout .trends__item.account-card:nth-child(5),
#mastodon .story.account-card:nth-child(5),
.public-layout .story.account-card:nth-child(5),
.modal-layout .story.account-card:nth-child(5),
#mastodon .account-card.account-card:nth-child(5),
.public-layout .account-card.account-card:nth-child(5),
.modal-layout .account-card.account-card:nth-child(5),
#mastodon :not(.focusable) > .account.account-card:nth-child(5),
.public-layout :not(.focusable) > .account.account-card:nth-child(5),
.modal-layout :not(.focusable) > .account.account-card:nth-child(5),
#mastodon .timeline-hint.account-card:nth-child(5),
.public-layout .timeline-hint.account-card:nth-child(5),
.modal-layout .timeline-hint.account-card:nth-child(5) {
  animation-delay: 0.2s;
}
#mastodon .focusable.trends__item:nth-child(6),
.public-layout .focusable.trends__item:nth-child(6),
.modal-layout .focusable.trends__item:nth-child(6),
#mastodon .entry.trends__item:nth-child(6),
.public-layout .entry.trends__item:nth-child(6),
.modal-layout .entry.trends__item:nth-child(6),
#mastodon .statuses-grid__item .detailed-status.trends__item:nth-child(6),
.public-layout .statuses-grid__item .detailed-status.trends__item:nth-child(6),
.modal-layout .statuses-grid__item .detailed-status.trends__item:nth-child(6),
#mastodon .trends__item.trends__item:nth-child(6),
.public-layout .trends__item.trends__item:nth-child(6),
.modal-layout .trends__item.trends__item:nth-child(6),
#mastodon .story.trends__item:nth-child(6),
.public-layout .story.trends__item:nth-child(6),
.modal-layout .story.trends__item:nth-child(6),
#mastodon .account-card.trends__item:nth-child(6),
.public-layout .account-card.trends__item:nth-child(6),
.modal-layout .account-card.trends__item:nth-child(6),
#mastodon :not(.focusable) > .account.trends__item:nth-child(6),
.public-layout :not(.focusable) > .account.trends__item:nth-child(6),
.modal-layout :not(.focusable) > .account.trends__item:nth-child(6),
#mastodon .timeline-hint.trends__item:nth-child(6),
.public-layout .timeline-hint.trends__item:nth-child(6),
.modal-layout .timeline-hint.trends__item:nth-child(6),
#mastodon .focusable.story:nth-child(6),
.public-layout .focusable.story:nth-child(6),
.modal-layout .focusable.story:nth-child(6),
#mastodon .entry.story:nth-child(6),
.public-layout .entry.story:nth-child(6),
.modal-layout .entry.story:nth-child(6),
#mastodon .statuses-grid__item .detailed-status.story:nth-child(6),
.public-layout .statuses-grid__item .detailed-status.story:nth-child(6),
.modal-layout .statuses-grid__item .detailed-status.story:nth-child(6),
#mastodon .trends__item.story:nth-child(6),
.public-layout .trends__item.story:nth-child(6),
.modal-layout .trends__item.story:nth-child(6),
#mastodon .story.story:nth-child(6),
.public-layout .story.story:nth-child(6),
.modal-layout .story.story:nth-child(6),
#mastodon .account-card.story:nth-child(6),
.public-layout .account-card.story:nth-child(6),
.modal-layout .account-card.story:nth-child(6),
#mastodon :not(.focusable) > .account.story:nth-child(6),
.public-layout :not(.focusable) > .account.story:nth-child(6),
.modal-layout :not(.focusable) > .account.story:nth-child(6),
#mastodon .timeline-hint.story:nth-child(6),
.public-layout .timeline-hint.story:nth-child(6),
.modal-layout .timeline-hint.story:nth-child(6),
#mastodon .focusable.account-card:nth-child(6),
.public-layout .focusable.account-card:nth-child(6),
.modal-layout .focusable.account-card:nth-child(6),
#mastodon .entry.account-card:nth-child(6),
.public-layout .entry.account-card:nth-child(6),
.modal-layout .entry.account-card:nth-child(6),
#mastodon .statuses-grid__item .detailed-status.account-card:nth-child(6),
.public-layout .statuses-grid__item .detailed-status.account-card:nth-child(6),
.modal-layout .statuses-grid__item .detailed-status.account-card:nth-child(6),
#mastodon .trends__item.account-card:nth-child(6),
.public-layout .trends__item.account-card:nth-child(6),
.modal-layout .trends__item.account-card:nth-child(6),
#mastodon .story.account-card:nth-child(6),
.public-layout .story.account-card:nth-child(6),
.modal-layout .story.account-card:nth-child(6),
#mastodon .account-card.account-card:nth-child(6),
.public-layout .account-card.account-card:nth-child(6),
.modal-layout .account-card.account-card:nth-child(6),
#mastodon :not(.focusable) > .account.account-card:nth-child(6),
.public-layout :not(.focusable) > .account.account-card:nth-child(6),
.modal-layout :not(.focusable) > .account.account-card:nth-child(6),
#mastodon .timeline-hint.account-card:nth-child(6),
.public-layout .timeline-hint.account-card:nth-child(6),
.modal-layout .timeline-hint.account-card:nth-child(6) {
  animation-delay: 0.24s;
}
#mastodon .focusable.trends__item:nth-child(7),
.public-layout .focusable.trends__item:nth-child(7),
.modal-layout .focusable.trends__item:nth-child(7),
#mastodon .entry.trends__item:nth-child(7),
.public-layout .entry.trends__item:nth-child(7),
.modal-layout .entry.trends__item:nth-child(7),
#mastodon .statuses-grid__item .detailed-status.trends__item:nth-child(7),
.public-layout .statuses-grid__item .detailed-status.trends__item:nth-child(7),
.modal-layout .statuses-grid__item .detailed-status.trends__item:nth-child(7),
#mastodon .trends__item.trends__item:nth-child(7),
.public-layout .trends__item.trends__item:nth-child(7),
.modal-layout .trends__item.trends__item:nth-child(7),
#mastodon .story.trends__item:nth-child(7),
.public-layout .story.trends__item:nth-child(7),
.modal-layout .story.trends__item:nth-child(7),
#mastodon .account-card.trends__item:nth-child(7),
.public-layout .account-card.trends__item:nth-child(7),
.modal-layout .account-card.trends__item:nth-child(7),
#mastodon :not(.focusable) > .account.trends__item:nth-child(7),
.public-layout :not(.focusable) > .account.trends__item:nth-child(7),
.modal-layout :not(.focusable) > .account.trends__item:nth-child(7),
#mastodon .timeline-hint.trends__item:nth-child(7),
.public-layout .timeline-hint.trends__item:nth-child(7),
.modal-layout .timeline-hint.trends__item:nth-child(7),
#mastodon .focusable.story:nth-child(7),
.public-layout .focusable.story:nth-child(7),
.modal-layout .focusable.story:nth-child(7),
#mastodon .entry.story:nth-child(7),
.public-layout .entry.story:nth-child(7),
.modal-layout .entry.story:nth-child(7),
#mastodon .statuses-grid__item .detailed-status.story:nth-child(7),
.public-layout .statuses-grid__item .detailed-status.story:nth-child(7),
.modal-layout .statuses-grid__item .detailed-status.story:nth-child(7),
#mastodon .trends__item.story:nth-child(7),
.public-layout .trends__item.story:nth-child(7),
.modal-layout .trends__item.story:nth-child(7),
#mastodon .story.story:nth-child(7),
.public-layout .story.story:nth-child(7),
.modal-layout .story.story:nth-child(7),
#mastodon .account-card.story:nth-child(7),
.public-layout .account-card.story:nth-child(7),
.modal-layout .account-card.story:nth-child(7),
#mastodon :not(.focusable) > .account.story:nth-child(7),
.public-layout :not(.focusable) > .account.story:nth-child(7),
.modal-layout :not(.focusable) > .account.story:nth-child(7),
#mastodon .timeline-hint.story:nth-child(7),
.public-layout .timeline-hint.story:nth-child(7),
.modal-layout .timeline-hint.story:nth-child(7),
#mastodon .focusable.account-card:nth-child(7),
.public-layout .focusable.account-card:nth-child(7),
.modal-layout .focusable.account-card:nth-child(7),
#mastodon .entry.account-card:nth-child(7),
.public-layout .entry.account-card:nth-child(7),
.modal-layout .entry.account-card:nth-child(7),
#mastodon .statuses-grid__item .detailed-status.account-card:nth-child(7),
.public-layout .statuses-grid__item .detailed-status.account-card:nth-child(7),
.modal-layout .statuses-grid__item .detailed-status.account-card:nth-child(7),
#mastodon .trends__item.account-card:nth-child(7),
.public-layout .trends__item.account-card:nth-child(7),
.modal-layout .trends__item.account-card:nth-child(7),
#mastodon .story.account-card:nth-child(7),
.public-layout .story.account-card:nth-child(7),
.modal-layout .story.account-card:nth-child(7),
#mastodon .account-card.account-card:nth-child(7),
.public-layout .account-card.account-card:nth-child(7),
.modal-layout .account-card.account-card:nth-child(7),
#mastodon :not(.focusable) > .account.account-card:nth-child(7),
.public-layout :not(.focusable) > .account.account-card:nth-child(7),
.modal-layout :not(.focusable) > .account.account-card:nth-child(7),
#mastodon .timeline-hint.account-card:nth-child(7),
.public-layout .timeline-hint.account-card:nth-child(7),
.modal-layout .timeline-hint.account-card:nth-child(7) {
  animation-delay: 0.28s;
}
#mastodon .focusable.trends__item:nth-child(8),
.public-layout .focusable.trends__item:nth-child(8),
.modal-layout .focusable.trends__item:nth-child(8),
#mastodon .entry.trends__item:nth-child(8),
.public-layout .entry.trends__item:nth-child(8),
.modal-layout .entry.trends__item:nth-child(8),
#mastodon .statuses-grid__item .detailed-status.trends__item:nth-child(8),
.public-layout .statuses-grid__item .detailed-status.trends__item:nth-child(8),
.modal-layout .statuses-grid__item .detailed-status.trends__item:nth-child(8),
#mastodon .trends__item.trends__item:nth-child(8),
.public-layout .trends__item.trends__item:nth-child(8),
.modal-layout .trends__item.trends__item:nth-child(8),
#mastodon .story.trends__item:nth-child(8),
.public-layout .story.trends__item:nth-child(8),
.modal-layout .story.trends__item:nth-child(8),
#mastodon .account-card.trends__item:nth-child(8),
.public-layout .account-card.trends__item:nth-child(8),
.modal-layout .account-card.trends__item:nth-child(8),
#mastodon :not(.focusable) > .account.trends__item:nth-child(8),
.public-layout :not(.focusable) > .account.trends__item:nth-child(8),
.modal-layout :not(.focusable) > .account.trends__item:nth-child(8),
#mastodon .timeline-hint.trends__item:nth-child(8),
.public-layout .timeline-hint.trends__item:nth-child(8),
.modal-layout .timeline-hint.trends__item:nth-child(8),
#mastodon .focusable.story:nth-child(8),
.public-layout .focusable.story:nth-child(8),
.modal-layout .focusable.story:nth-child(8),
#mastodon .entry.story:nth-child(8),
.public-layout .entry.story:nth-child(8),
.modal-layout .entry.story:nth-child(8),
#mastodon .statuses-grid__item .detailed-status.story:nth-child(8),
.public-layout .statuses-grid__item .detailed-status.story:nth-child(8),
.modal-layout .statuses-grid__item .detailed-status.story:nth-child(8),
#mastodon .trends__item.story:nth-child(8),
.public-layout .trends__item.story:nth-child(8),
.modal-layout .trends__item.story:nth-child(8),
#mastodon .story.story:nth-child(8),
.public-layout .story.story:nth-child(8),
.modal-layout .story.story:nth-child(8),
#mastodon .account-card.story:nth-child(8),
.public-layout .account-card.story:nth-child(8),
.modal-layout .account-card.story:nth-child(8),
#mastodon :not(.focusable) > .account.story:nth-child(8),
.public-layout :not(.focusable) > .account.story:nth-child(8),
.modal-layout :not(.focusable) > .account.story:nth-child(8),
#mastodon .timeline-hint.story:nth-child(8),
.public-layout .timeline-hint.story:nth-child(8),
.modal-layout .timeline-hint.story:nth-child(8),
#mastodon .focusable.account-card:nth-child(8),
.public-layout .focusable.account-card:nth-child(8),
.modal-layout .focusable.account-card:nth-child(8),
#mastodon .entry.account-card:nth-child(8),
.public-layout .entry.account-card:nth-child(8),
.modal-layout .entry.account-card:nth-child(8),
#mastodon .statuses-grid__item .detailed-status.account-card:nth-child(8),
.public-layout .statuses-grid__item .detailed-status.account-card:nth-child(8),
.modal-layout .statuses-grid__item .detailed-status.account-card:nth-child(8),
#mastodon .trends__item.account-card:nth-child(8),
.public-layout .trends__item.account-card:nth-child(8),
.modal-layout .trends__item.account-card:nth-child(8),
#mastodon .story.account-card:nth-child(8),
.public-layout .story.account-card:nth-child(8),
.modal-layout .story.account-card:nth-child(8),
#mastodon .account-card.account-card:nth-child(8),
.public-layout .account-card.account-card:nth-child(8),
.modal-layout .account-card.account-card:nth-child(8),
#mastodon :not(.focusable) > .account.account-card:nth-child(8),
.public-layout :not(.focusable) > .account.account-card:nth-child(8),
.modal-layout :not(.focusable) > .account.account-card:nth-child(8),
#mastodon .timeline-hint.account-card:nth-child(8),
.public-layout .timeline-hint.account-card:nth-child(8),
.modal-layout .timeline-hint.account-card:nth-child(8) {
  animation-delay: 0.32s;
}
#mastodon .focusable.trends__item:nth-child(9),
.public-layout .focusable.trends__item:nth-child(9),
.modal-layout .focusable.trends__item:nth-child(9),
#mastodon .entry.trends__item:nth-child(9),
.public-layout .entry.trends__item:nth-child(9),
.modal-layout .entry.trends__item:nth-child(9),
#mastodon .statuses-grid__item .detailed-status.trends__item:nth-child(9),
.public-layout .statuses-grid__item .detailed-status.trends__item:nth-child(9),
.modal-layout .statuses-grid__item .detailed-status.trends__item:nth-child(9),
#mastodon .trends__item.trends__item:nth-child(9),
.public-layout .trends__item.trends__item:nth-child(9),
.modal-layout .trends__item.trends__item:nth-child(9),
#mastodon .story.trends__item:nth-child(9),
.public-layout .story.trends__item:nth-child(9),
.modal-layout .story.trends__item:nth-child(9),
#mastodon .account-card.trends__item:nth-child(9),
.public-layout .account-card.trends__item:nth-child(9),
.modal-layout .account-card.trends__item:nth-child(9),
#mastodon :not(.focusable) > .account.trends__item:nth-child(9),
.public-layout :not(.focusable) > .account.trends__item:nth-child(9),
.modal-layout :not(.focusable) > .account.trends__item:nth-child(9),
#mastodon .timeline-hint.trends__item:nth-child(9),
.public-layout .timeline-hint.trends__item:nth-child(9),
.modal-layout .timeline-hint.trends__item:nth-child(9),
#mastodon .focusable.story:nth-child(9),
.public-layout .focusable.story:nth-child(9),
.modal-layout .focusable.story:nth-child(9),
#mastodon .entry.story:nth-child(9),
.public-layout .entry.story:nth-child(9),
.modal-layout .entry.story:nth-child(9),
#mastodon .statuses-grid__item .detailed-status.story:nth-child(9),
.public-layout .statuses-grid__item .detailed-status.story:nth-child(9),
.modal-layout .statuses-grid__item .detailed-status.story:nth-child(9),
#mastodon .trends__item.story:nth-child(9),
.public-layout .trends__item.story:nth-child(9),
.modal-layout .trends__item.story:nth-child(9),
#mastodon .story.story:nth-child(9),
.public-layout .story.story:nth-child(9),
.modal-layout .story.story:nth-child(9),
#mastodon .account-card.story:nth-child(9),
.public-layout .account-card.story:nth-child(9),
.modal-layout .account-card.story:nth-child(9),
#mastodon :not(.focusable) > .account.story:nth-child(9),
.public-layout :not(.focusable) > .account.story:nth-child(9),
.modal-layout :not(.focusable) > .account.story:nth-child(9),
#mastodon .timeline-hint.story:nth-child(9),
.public-layout .timeline-hint.story:nth-child(9),
.modal-layout .timeline-hint.story:nth-child(9),
#mastodon .focusable.account-card:nth-child(9),
.public-layout .focusable.account-card:nth-child(9),
.modal-layout .focusable.account-card:nth-child(9),
#mastodon .entry.account-card:nth-child(9),
.public-layout .entry.account-card:nth-child(9),
.modal-layout .entry.account-card:nth-child(9),
#mastodon .statuses-grid__item .detailed-status.account-card:nth-child(9),
.public-layout .statuses-grid__item .detailed-status.account-card:nth-child(9),
.modal-layout .statuses-grid__item .detailed-status.account-card:nth-child(9),
#mastodon .trends__item.account-card:nth-child(9),
.public-layout .trends__item.account-card:nth-child(9),
.modal-layout .trends__item.account-card:nth-child(9),
#mastodon .story.account-card:nth-child(9),
.public-layout .story.account-card:nth-child(9),
.modal-layout .story.account-card:nth-child(9),
#mastodon .account-card.account-card:nth-child(9),
.public-layout .account-card.account-card:nth-child(9),
.modal-layout .account-card.account-card:nth-child(9),
#mastodon :not(.focusable) > .account.account-card:nth-child(9),
.public-layout :not(.focusable) > .account.account-card:nth-child(9),
.modal-layout :not(.focusable) > .account.account-card:nth-child(9),
#mastodon .timeline-hint.account-card:nth-child(9),
.public-layout .timeline-hint.account-card:nth-child(9),
.modal-layout .timeline-hint.account-card:nth-child(9) {
  animation-delay: 0.36s;
}
#mastodon .focusable.trends__item:nth-child(10),
.public-layout .focusable.trends__item:nth-child(10),
.modal-layout .focusable.trends__item:nth-child(10),
#mastodon .entry.trends__item:nth-child(10),
.public-layout .entry.trends__item:nth-child(10),
.modal-layout .entry.trends__item:nth-child(10),
#mastodon .statuses-grid__item .detailed-status.trends__item:nth-child(10),
.public-layout .statuses-grid__item .detailed-status.trends__item:nth-child(10),
.modal-layout .statuses-grid__item .detailed-status.trends__item:nth-child(10),
#mastodon .trends__item.trends__item:nth-child(10),
.public-layout .trends__item.trends__item:nth-child(10),
.modal-layout .trends__item.trends__item:nth-child(10),
#mastodon .story.trends__item:nth-child(10),
.public-layout .story.trends__item:nth-child(10),
.modal-layout .story.trends__item:nth-child(10),
#mastodon .account-card.trends__item:nth-child(10),
.public-layout .account-card.trends__item:nth-child(10),
.modal-layout .account-card.trends__item:nth-child(10),
#mastodon :not(.focusable) > .account.trends__item:nth-child(10),
.public-layout :not(.focusable) > .account.trends__item:nth-child(10),
.modal-layout :not(.focusable) > .account.trends__item:nth-child(10),
#mastodon .timeline-hint.trends__item:nth-child(10),
.public-layout .timeline-hint.trends__item:nth-child(10),
.modal-layout .timeline-hint.trends__item:nth-child(10),
#mastodon .focusable.story:nth-child(10),
.public-layout .focusable.story:nth-child(10),
.modal-layout .focusable.story:nth-child(10),
#mastodon .entry.story:nth-child(10),
.public-layout .entry.story:nth-child(10),
.modal-layout .entry.story:nth-child(10),
#mastodon .statuses-grid__item .detailed-status.story:nth-child(10),
.public-layout .statuses-grid__item .detailed-status.story:nth-child(10),
.modal-layout .statuses-grid__item .detailed-status.story:nth-child(10),
#mastodon .trends__item.story:nth-child(10),
.public-layout .trends__item.story:nth-child(10),
.modal-layout .trends__item.story:nth-child(10),
#mastodon .story.story:nth-child(10),
.public-layout .story.story:nth-child(10),
.modal-layout .story.story:nth-child(10),
#mastodon .account-card.story:nth-child(10),
.public-layout .account-card.story:nth-child(10),
.modal-layout .account-card.story:nth-child(10),
#mastodon :not(.focusable) > .account.story:nth-child(10),
.public-layout :not(.focusable) > .account.story:nth-child(10),
.modal-layout :not(.focusable) > .account.story:nth-child(10),
#mastodon .timeline-hint.story:nth-child(10),
.public-layout .timeline-hint.story:nth-child(10),
.modal-layout .timeline-hint.story:nth-child(10),
#mastodon .focusable.account-card:nth-child(10),
.public-layout .focusable.account-card:nth-child(10),
.modal-layout .focusable.account-card:nth-child(10),
#mastodon .entry.account-card:nth-child(10),
.public-layout .entry.account-card:nth-child(10),
.modal-layout .entry.account-card:nth-child(10),
#mastodon .statuses-grid__item .detailed-status.account-card:nth-child(10),
.public-layout .statuses-grid__item .detailed-status.account-card:nth-child(10),
.modal-layout .statuses-grid__item .detailed-status.account-card:nth-child(10),
#mastodon .trends__item.account-card:nth-child(10),
.public-layout .trends__item.account-card:nth-child(10),
.modal-layout .trends__item.account-card:nth-child(10),
#mastodon .story.account-card:nth-child(10),
.public-layout .story.account-card:nth-child(10),
.modal-layout .story.account-card:nth-child(10),
#mastodon .account-card.account-card:nth-child(10),
.public-layout .account-card.account-card:nth-child(10),
.modal-layout .account-card.account-card:nth-child(10),
#mastodon :not(.focusable) > .account.account-card:nth-child(10),
.public-layout :not(.focusable) > .account.account-card:nth-child(10),
.modal-layout :not(.focusable) > .account.account-card:nth-child(10),
#mastodon .timeline-hint.account-card:nth-child(10),
.public-layout .timeline-hint.account-card:nth-child(10),
.modal-layout .timeline-hint.account-card:nth-child(10) {
  animation-delay: 0.4s;
}
#mastodon .focusable.focusable,
.public-layout .focusable.focusable,
.modal-layout .focusable.focusable,
#mastodon .entry.focusable,
.public-layout .entry.focusable,
.modal-layout .entry.focusable,
#mastodon .statuses-grid__item .detailed-status.focusable,
.public-layout .statuses-grid__item .detailed-status.focusable,
.modal-layout .statuses-grid__item .detailed-status.focusable,
#mastodon .trends__item.focusable,
.public-layout .trends__item.focusable,
.modal-layout .trends__item.focusable,
#mastodon .story.focusable,
.public-layout .story.focusable,
.modal-layout .story.focusable,
#mastodon .account-card.focusable,
.public-layout .account-card.focusable,
.modal-layout .account-card.focusable,
#mastodon :not(.focusable) > .account.focusable,
.public-layout :not(.focusable) > .account.focusable,
.modal-layout :not(.focusable) > .account.focusable,
#mastodon .timeline-hint.focusable,
.public-layout .timeline-hint.focusable,
.modal-layout .timeline-hint.focusable {
  background: none;
}
#mastodon .focusable.entry,
.public-layout .focusable.entry,
.modal-layout .focusable.entry,
#mastodon .entry.entry,
.public-layout .entry.entry,
.modal-layout .entry.entry,
#mastodon .statuses-grid__item .detailed-status.entry,
.public-layout .statuses-grid__item .detailed-status.entry,
.modal-layout .statuses-grid__item .detailed-status.entry,
#mastodon .trends__item.entry,
.public-layout .trends__item.entry,
.modal-layout .trends__item.entry,
#mastodon .story.entry,
.public-layout .story.entry,
.modal-layout .story.entry,
#mastodon .account-card.entry,
.public-layout .account-card.entry,
.modal-layout .account-card.entry,
#mastodon :not(.focusable) > .account.entry,
.public-layout :not(.focusable) > .account.entry,
.modal-layout :not(.focusable) > .account.entry,
#mastodon .timeline-hint.entry,
.public-layout .timeline-hint.entry,
.modal-layout .timeline-hint.entry {
  margin-bottom: 10px;
}
#mastodon .focusable:not(.detailed-status__wrapper)::after,
.public-layout .focusable:not(.detailed-status__wrapper)::after,
.modal-layout .focusable:not(.detailed-status__wrapper)::after,
#mastodon .entry:not(.detailed-status__wrapper)::after,
.public-layout .entry:not(.detailed-status__wrapper)::after,
.modal-layout .entry:not(.detailed-status__wrapper)::after,
#mastodon .statuses-grid__item .detailed-status:not(.detailed-status__wrapper)::after,
.public-layout .statuses-grid__item .detailed-status:not(.detailed-status__wrapper)::after,
.modal-layout .statuses-grid__item .detailed-status:not(.detailed-status__wrapper)::after,
#mastodon .trends__item:not(.detailed-status__wrapper)::after,
.public-layout .trends__item:not(.detailed-status__wrapper)::after,
.modal-layout .trends__item:not(.detailed-status__wrapper)::after,
#mastodon .story:not(.detailed-status__wrapper)::after,
.public-layout .story:not(.detailed-status__wrapper)::after,
.modal-layout .story:not(.detailed-status__wrapper)::after,
#mastodon .account-card:not(.detailed-status__wrapper)::after,
.public-layout .account-card:not(.detailed-status__wrapper)::after,
.modal-layout .account-card:not(.detailed-status__wrapper)::after,
#mastodon :not(.focusable) > .account:not(.detailed-status__wrapper)::after,
.public-layout :not(.focusable) > .account:not(.detailed-status__wrapper)::after,
.modal-layout :not(.focusable) > .account:not(.detailed-status__wrapper)::after,
#mastodon .timeline-hint:not(.detailed-status__wrapper)::after,
.public-layout .timeline-hint:not(.detailed-status__wrapper)::after,
.modal-layout .timeline-hint:not(.detailed-status__wrapper)::after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: var(--radius);
  right: var(--radius);
  border-top: 1px solid var(--border-color);
  pointer-events: none;
}
#mastodon .focusable:not(.detailed-status__wrapper)::before,
.public-layout .focusable:not(.detailed-status__wrapper)::before,
.modal-layout .focusable:not(.detailed-status__wrapper)::before,
#mastodon .entry:not(.detailed-status__wrapper)::before,
.public-layout .entry:not(.detailed-status__wrapper)::before,
.modal-layout .entry:not(.detailed-status__wrapper)::before,
#mastodon .statuses-grid__item .detailed-status:not(.detailed-status__wrapper)::before,
.public-layout .statuses-grid__item .detailed-status:not(.detailed-status__wrapper)::before,
.modal-layout .statuses-grid__item .detailed-status:not(.detailed-status__wrapper)::before,
#mastodon .trends__item:not(.detailed-status__wrapper)::before,
.public-layout .trends__item:not(.detailed-status__wrapper)::before,
.modal-layout .trends__item:not(.detailed-status__wrapper)::before,
#mastodon .story:not(.detailed-status__wrapper)::before,
.public-layout .story:not(.detailed-status__wrapper)::before,
.modal-layout .story:not(.detailed-status__wrapper)::before,
#mastodon .account-card:not(.detailed-status__wrapper)::before,
.public-layout .account-card:not(.detailed-status__wrapper)::before,
.modal-layout .account-card:not(.detailed-status__wrapper)::before,
#mastodon :not(.focusable) > .account:not(.detailed-status__wrapper)::before,
.public-layout :not(.focusable) > .account:not(.detailed-status__wrapper)::before,
.modal-layout :not(.focusable) > .account:not(.detailed-status__wrapper)::before,
#mastodon .timeline-hint:not(.detailed-status__wrapper)::before,
.public-layout .timeline-hint:not(.detailed-status__wrapper)::before,
.modal-layout .timeline-hint:not(.detailed-status__wrapper)::before {
  content: "";
  position: absolute;
  inset: 0;
  bottom: 1px;
  pointer-events: none;
  transition: background-color 0.2s;
}
#mastodon .focusable:not(.detailed-status__wrapper):hover::before,
.public-layout .focusable:not(.detailed-status__wrapper):hover::before,
.modal-layout .focusable:not(.detailed-status__wrapper):hover::before,
#mastodon .entry:not(.detailed-status__wrapper):hover::before,
.public-layout .entry:not(.detailed-status__wrapper):hover::before,
.modal-layout .entry:not(.detailed-status__wrapper):hover::before,
#mastodon .statuses-grid__item .detailed-status:not(.detailed-status__wrapper):hover::before,
.public-layout .statuses-grid__item .detailed-status:not(.detailed-status__wrapper):hover::before,
.modal-layout .statuses-grid__item .detailed-status:not(.detailed-status__wrapper):hover::before,
#mastodon .trends__item:not(.detailed-status__wrapper):hover::before,
.public-layout .trends__item:not(.detailed-status__wrapper):hover::before,
.modal-layout .trends__item:not(.detailed-status__wrapper):hover::before,
#mastodon .story:not(.detailed-status__wrapper):hover::before,
.public-layout .story:not(.detailed-status__wrapper):hover::before,
.modal-layout .story:not(.detailed-status__wrapper):hover::before,
#mastodon .account-card:not(.detailed-status__wrapper):hover::before,
.public-layout .account-card:not(.detailed-status__wrapper):hover::before,
.modal-layout .account-card:not(.detailed-status__wrapper):hover::before,
#mastodon :not(.focusable) > .account:not(.detailed-status__wrapper):hover::before,
.public-layout :not(.focusable) > .account:not(.detailed-status__wrapper):hover::before,
.modal-layout :not(.focusable) > .account:not(.detailed-status__wrapper):hover::before,
#mastodon .timeline-hint:not(.detailed-status__wrapper):hover::before,
.public-layout .timeline-hint:not(.detailed-status__wrapper):hover::before,
.modal-layout .timeline-hint:not(.detailed-status__wrapper):hover::before,
#mastodon .focusable:not(.detailed-status__wrapper):focus-within::before,
.public-layout .focusable:not(.detailed-status__wrapper):focus-within::before,
.modal-layout .focusable:not(.detailed-status__wrapper):focus-within::before,
#mastodon .entry:not(.detailed-status__wrapper):focus-within::before,
.public-layout .entry:not(.detailed-status__wrapper):focus-within::before,
.modal-layout .entry:not(.detailed-status__wrapper):focus-within::before,
#mastodon .statuses-grid__item .detailed-status:not(.detailed-status__wrapper):focus-within::before,
.public-layout .statuses-grid__item .detailed-status:not(.detailed-status__wrapper):focus-within::before,
.modal-layout .statuses-grid__item .detailed-status:not(.detailed-status__wrapper):focus-within::before,
#mastodon .trends__item:not(.detailed-status__wrapper):focus-within::before,
.public-layout .trends__item:not(.detailed-status__wrapper):focus-within::before,
.modal-layout .trends__item:not(.detailed-status__wrapper):focus-within::before,
#mastodon .story:not(.detailed-status__wrapper):focus-within::before,
.public-layout .story:not(.detailed-status__wrapper):focus-within::before,
.modal-layout .story:not(.detailed-status__wrapper):focus-within::before,
#mastodon .account-card:not(.detailed-status__wrapper):focus-within::before,
.public-layout .account-card:not(.detailed-status__wrapper):focus-within::before,
.modal-layout .account-card:not(.detailed-status__wrapper):focus-within::before,
#mastodon :not(.focusable) > .account:not(.detailed-status__wrapper):focus-within::before,
.public-layout :not(.focusable) > .account:not(.detailed-status__wrapper):focus-within::before,
.modal-layout :not(.focusable) > .account:not(.detailed-status__wrapper):focus-within::before,
#mastodon .timeline-hint:not(.detailed-status__wrapper):focus-within::before,
.public-layout .timeline-hint:not(.detailed-status__wrapper):focus-within::before,
.modal-layout .timeline-hint:not(.detailed-status__wrapper):focus-within::before {
  background-color: var(--hover-color);
}
.explore__links {
  padding: 10px !important;
  display: flex;
  flex-wrap: wrap;
}
.explore__links .trends__item {
  margin: 10px;
  padding: 25px !important;
  box-shadow: var(--shadow-med);
  width: 200px;
}
.explore__links .trends__item::after {
  content: unset !important;
  inset: 0 !important;
  border-radius: var(--radius);
  pointer-events: none;
  border: 1px solid var(--border-color) !important;
}
.explore__links .trends__item a {
  font-size: 1.4em;
  line-height: 1.7em;
}
.explore__links .trends__item .trends__item__sparkline {
  height: 100%;
}
.explore__links .trends__item .trends__item__sparkline svg {
  height: 100%;
  float: right;
  overflow: visible !important;
  position: relative;
  z-index: -1;
}
.explore__links .trends__item .trends__item__sparkline svg path {
  display: unset !important;
  transition: transform 1s;
}
.explore__links .trends__item .trends__item__sparkline svg path:first-child {
  transform-origin: center;
}
.explore__links .trends__item:hover svg path:first-child,
.explore__links .trends__item:focus-within svg path:first-child {
  transform: scale(2);
}
#mastodon .focusable.trends__item,
.public-layout .focusable.trends__item,
.modal-layout .focusable.trends__item,
#mastodon .entry.trends__item,
.public-layout .entry.trends__item,
.modal-layout .entry.trends__item,
#mastodon .statuses-grid__item .detailed-status.trends__item,
.public-layout .statuses-grid__item .detailed-status.trends__item,
.modal-layout .statuses-grid__item .detailed-status.trends__item,
#mastodon .trends__item.trends__item,
.public-layout .trends__item.trends__item,
.modal-layout .trends__item.trends__item,
#mastodon .story.trends__item,
.public-layout .story.trends__item,
.modal-layout .story.trends__item,
#mastodon .account-card.trends__item,
.public-layout .account-card.trends__item,
.modal-layout .account-card.trends__item,
#mastodon :not(.focusable) > .account.trends__item,
.public-layout :not(.focusable) > .account.trends__item,
.modal-layout :not(.focusable) > .account.trends__item,
#mastodon .timeline-hint.trends__item,
.public-layout .timeline-hint.trends__item,
.modal-layout .timeline-hint.trends__item,
#mastodon .focusable.story,
.public-layout .focusable.story,
.modal-layout .focusable.story,
#mastodon .entry.story,
.public-layout .entry.story,
.modal-layout .entry.story,
#mastodon .statuses-grid__item .detailed-status.story,
.public-layout .statuses-grid__item .detailed-status.story,
.modal-layout .statuses-grid__item .detailed-status.story,
#mastodon .trends__item.story,
.public-layout .trends__item.story,
.modal-layout .trends__item.story,
#mastodon .story.story,
.public-layout .story.story,
.modal-layout .story.story,
#mastodon .account-card.story,
.public-layout .account-card.story,
.modal-layout .account-card.story,
#mastodon :not(.focusable) > .account.story,
.public-layout :not(.focusable) > .account.story,
.modal-layout :not(.focusable) > .account.story,
#mastodon .timeline-hint.story,
.public-layout .timeline-hint.story,
.modal-layout .timeline-hint.story {
  padding: 10px;
  flex-grow: 1;
}
#mastodon .focusable.story,
.public-layout .focusable.story,
.modal-layout .focusable.story,
#mastodon .entry.story,
.public-layout .entry.story,
.modal-layout .entry.story,
#mastodon .statuses-grid__item .detailed-status.story,
.public-layout .statuses-grid__item .detailed-status.story,
.modal-layout .statuses-grid__item .detailed-status.story,
#mastodon .trends__item.story,
.public-layout .trends__item.story,
.modal-layout .trends__item.story,
#mastodon .story.story,
.public-layout .story.story,
.modal-layout .story.story,
#mastodon .account-card.story,
.public-layout .account-card.story,
.modal-layout .account-card.story,
#mastodon :not(.focusable) > .account.story,
.public-layout :not(.focusable) > .account.story,
.modal-layout :not(.focusable) > .account.story,
#mastodon .timeline-hint.story,
.public-layout .timeline-hint.story,
.modal-layout .timeline-hint.story {
  padding: 15px;
}
#mastodon .focusable.story .story__details,
.public-layout .focusable.story .story__details,
.modal-layout .focusable.story .story__details,
#mastodon .entry.story .story__details,
.public-layout .entry.story .story__details,
.modal-layout .entry.story .story__details,
#mastodon .statuses-grid__item .detailed-status.story .story__details,
.public-layout .statuses-grid__item .detailed-status.story .story__details,
.modal-layout .statuses-grid__item .detailed-status.story .story__details,
#mastodon .trends__item.story .story__details,
.public-layout .trends__item.story .story__details,
.modal-layout .trends__item.story .story__details,
#mastodon .story.story .story__details,
.public-layout .story.story .story__details,
.modal-layout .story.story .story__details,
#mastodon .account-card.story .story__details,
.public-layout .account-card.story .story__details,
.modal-layout .account-card.story .story__details,
#mastodon :not(.focusable) > .account.story .story__details,
.public-layout :not(.focusable) > .account.story .story__details,
.modal-layout :not(.focusable) > .account.story .story__details,
#mastodon .timeline-hint.story .story__details,
.public-layout .timeline-hint.story .story__details,
.modal-layout .timeline-hint.story .story__details {
  padding-left: 0 !important;
}
#mastodon .focusable.story .story__thumbnail,
.public-layout .focusable.story .story__thumbnail,
.modal-layout .focusable.story .story__thumbnail,
#mastodon .entry.story .story__thumbnail,
.public-layout .entry.story .story__thumbnail,
.modal-layout .entry.story .story__thumbnail,
#mastodon .statuses-grid__item .detailed-status.story .story__thumbnail,
.public-layout .statuses-grid__item .detailed-status.story .story__thumbnail,
.modal-layout .statuses-grid__item .detailed-status.story .story__thumbnail,
#mastodon .trends__item.story .story__thumbnail,
.public-layout .trends__item.story .story__thumbnail,
.modal-layout .trends__item.story .story__thumbnail,
#mastodon .story.story .story__thumbnail,
.public-layout .story.story .story__thumbnail,
.modal-layout .story.story .story__thumbnail,
#mastodon .account-card.story .story__thumbnail,
.public-layout .account-card.story .story__thumbnail,
.modal-layout .account-card.story .story__thumbnail,
#mastodon :not(.focusable) > .account.story .story__thumbnail,
.public-layout :not(.focusable) > .account.story .story__thumbnail,
.modal-layout :not(.focusable) > .account.story .story__thumbnail,
#mastodon .timeline-hint.story .story__thumbnail,
.public-layout .timeline-hint.story .story__thumbnail,
.modal-layout .timeline-hint.story .story__thumbnail {
  margin-right: 0;
}
#mastodon .focusable.account-card,
.public-layout .focusable.account-card,
.modal-layout .focusable.account-card,
#mastodon .entry.account-card,
.public-layout .entry.account-card,
.modal-layout .entry.account-card,
#mastodon .statuses-grid__item .detailed-status.account-card,
.public-layout .statuses-grid__item .detailed-status.account-card,
.modal-layout .statuses-grid__item .detailed-status.account-card,
#mastodon .trends__item.account-card,
.public-layout .trends__item.account-card,
.modal-layout .trends__item.account-card,
#mastodon .story.account-card,
.public-layout .story.account-card,
.modal-layout .story.account-card,
#mastodon .account-card.account-card,
.public-layout .account-card.account-card,
.modal-layout .account-card.account-card,
#mastodon :not(.focusable) > .account.account-card,
.public-layout :not(.focusable) > .account.account-card,
.modal-layout :not(.focusable) > .account.account-card,
#mastodon .timeline-hint.account-card,
.public-layout .timeline-hint.account-card,
.modal-layout .timeline-hint.account-card {
  display: flex;
  flex-direction: column;
  box-shadow: var(--shadow);
}
.explore__suggestions {
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}
#mastodon .focusable.account-card .account-card__header,
.public-layout .focusable.account-card .account-card__header,
.modal-layout .focusable.account-card .account-card__header,
#mastodon .entry.account-card .account-card__header,
.public-layout .entry.account-card .account-card__header,
.modal-layout .entry.account-card .account-card__header,
#mastodon .statuses-grid__item .detailed-status.account-card .account-card__header,
.public-layout .statuses-grid__item .detailed-status.account-card .account-card__header,
.modal-layout .statuses-grid__item .detailed-status.account-card .account-card__header,
#mastodon .trends__item.account-card .account-card__header,
.public-layout .trends__item.account-card .account-card__header,
.modal-layout .trends__item.account-card .account-card__header,
#mastodon .story.account-card .account-card__header,
.public-layout .story.account-card .account-card__header,
.modal-layout .story.account-card .account-card__header,
#mastodon .account-card.account-card .account-card__header,
.public-layout .account-card.account-card .account-card__header,
.modal-layout .account-card.account-card .account-card__header,
#mastodon :not(.focusable) > .account.account-card .account-card__header,
.public-layout :not(.focusable) > .account.account-card .account-card__header,
.modal-layout :not(.focusable) > .account.account-card .account-card__header,
#mastodon .timeline-hint.account-card .account-card__header,
.public-layout .timeline-hint.account-card .account-card__header,
.modal-layout .timeline-hint.account-card .account-card__header {
  padding: 0 !important;
}
#mastodon .focusable.account-card .account-card__title__avatar .account__avatar,
.public-layout .focusable.account-card .account-card__title__avatar .account__avatar,
.modal-layout .focusable.account-card .account-card__title__avatar .account__avatar,
#mastodon .entry.account-card .account-card__title__avatar .account__avatar,
.public-layout .entry.account-card .account-card__title__avatar .account__avatar,
.modal-layout .entry.account-card .account-card__title__avatar .account__avatar,
#mastodon .statuses-grid__item .detailed-status.account-card .account-card__title__avatar .account__avatar,
.public-layout .statuses-grid__item .detailed-status.account-card .account-card__title__avatar .account__avatar,
.modal-layout .statuses-grid__item .detailed-status.account-card .account-card__title__avatar .account__avatar,
#mastodon .trends__item.account-card .account-card__title__avatar .account__avatar,
.public-layout .trends__item.account-card .account-card__title__avatar .account__avatar,
.modal-layout .trends__item.account-card .account-card__title__avatar .account__avatar,
#mastodon .story.account-card .account-card__title__avatar .account__avatar,
.public-layout .story.account-card .account-card__title__avatar .account__avatar,
.modal-layout .story.account-card .account-card__title__avatar .account__avatar,
#mastodon .account-card.account-card .account-card__title__avatar .account__avatar,
.public-layout .account-card.account-card .account-card__title__avatar .account__avatar,
.modal-layout .account-card.account-card .account-card__title__avatar .account__avatar,
#mastodon :not(.focusable) > .account.account-card .account-card__title__avatar .account__avatar,
.public-layout :not(.focusable) > .account.account-card .account-card__title__avatar .account__avatar,
.modal-layout :not(.focusable) > .account.account-card .account-card__title__avatar .account__avatar,
#mastodon .timeline-hint.account-card .account-card__title__avatar .account__avatar,
.public-layout .timeline-hint.account-card .account-card__title__avatar .account__avatar,
.modal-layout .timeline-hint.account-card .account-card__title__avatar .account__avatar {
  width: 64px !important;
  height: 64px !important;
  background-size: 64px 64px !important;
}
#mastodon .focusable.account-card .account-card__bio,
.public-layout .focusable.account-card .account-card__bio,
.modal-layout .focusable.account-card .account-card__bio,
#mastodon .entry.account-card .account-card__bio,
.public-layout .entry.account-card .account-card__bio,
.modal-layout .entry.account-card .account-card__bio,
#mastodon .statuses-grid__item .detailed-status.account-card .account-card__bio,
.public-layout .statuses-grid__item .detailed-status.account-card .account-card__bio,
.modal-layout .statuses-grid__item .detailed-status.account-card .account-card__bio,
#mastodon .trends__item.account-card .account-card__bio,
.public-layout .trends__item.account-card .account-card__bio,
.modal-layout .trends__item.account-card .account-card__bio,
#mastodon .story.account-card .account-card__bio,
.public-layout .story.account-card .account-card__bio,
.modal-layout .story.account-card .account-card__bio,
#mastodon .account-card.account-card .account-card__bio,
.public-layout .account-card.account-card .account-card__bio,
.modal-layout .account-card.account-card .account-card__bio,
#mastodon :not(.focusable) > .account.account-card .account-card__bio,
.public-layout :not(.focusable) > .account.account-card .account-card__bio,
.modal-layout :not(.focusable) > .account.account-card .account-card__bio,
#mastodon .timeline-hint.account-card .account-card__bio,
.public-layout .timeline-hint.account-card .account-card__bio,
.modal-layout .timeline-hint.account-card .account-card__bio {
  margin-top: 0 !important;
}
#mastodon .focusable.account-card .account-card__actions,
.public-layout .focusable.account-card .account-card__actions,
.modal-layout .focusable.account-card .account-card__actions,
#mastodon .entry.account-card .account-card__actions,
.public-layout .entry.account-card .account-card__actions,
.modal-layout .entry.account-card .account-card__actions,
#mastodon .statuses-grid__item .detailed-status.account-card .account-card__actions,
.public-layout .statuses-grid__item .detailed-status.account-card .account-card__actions,
.modal-layout .statuses-grid__item .detailed-status.account-card .account-card__actions,
#mastodon .trends__item.account-card .account-card__actions,
.public-layout .trends__item.account-card .account-card__actions,
.modal-layout .trends__item.account-card .account-card__actions,
#mastodon .story.account-card .account-card__actions,
.public-layout .story.account-card .account-card__actions,
.modal-layout .story.account-card .account-card__actions,
#mastodon .account-card.account-card .account-card__actions,
.public-layout .account-card.account-card .account-card__actions,
.modal-layout .account-card.account-card .account-card__actions,
#mastodon :not(.focusable) > .account.account-card .account-card__actions,
.public-layout :not(.focusable) > .account.account-card .account-card__actions,
.modal-layout :not(.focusable) > .account.account-card .account-card__actions,
#mastodon .timeline-hint.account-card .account-card__actions,
.public-layout .timeline-hint.account-card .account-card__actions,
.modal-layout .timeline-hint.account-card .account-card__actions {
  margin-top: auto !important;
}
#mastodon .focusable.timeline-hint a::before,
.public-layout .focusable.timeline-hint a::before,
.modal-layout .focusable.timeline-hint a::before,
#mastodon .entry.timeline-hint a::before,
.public-layout .entry.timeline-hint a::before,
.modal-layout .entry.timeline-hint a::before,
#mastodon .statuses-grid__item .detailed-status.timeline-hint a::before,
.public-layout .statuses-grid__item .detailed-status.timeline-hint a::before,
.modal-layout .statuses-grid__item .detailed-status.timeline-hint a::before,
#mastodon .trends__item.timeline-hint a::before,
.public-layout .trends__item.timeline-hint a::before,
.modal-layout .trends__item.timeline-hint a::before,
#mastodon .story.timeline-hint a::before,
.public-layout .story.timeline-hint a::before,
.modal-layout .story.timeline-hint a::before,
#mastodon .account-card.timeline-hint a::before,
.public-layout .account-card.timeline-hint a::before,
.modal-layout .account-card.timeline-hint a::before,
#mastodon :not(.focusable) > .account.timeline-hint a::before,
.public-layout :not(.focusable) > .account.timeline-hint a::before,
.modal-layout :not(.focusable) > .account.timeline-hint a::before,
#mastodon .timeline-hint.timeline-hint a::before,
.public-layout .timeline-hint.timeline-hint a::before,
.modal-layout .timeline-hint.timeline-hint a::before {
  content: "";
  position: absolute;
  inset: 0;
}
#mastodon .status,
.public-layout .status,
.modal-layout .status,
#mastodon .focusable > .account,
.public-layout .focusable > .account,
.modal-layout .focusable > .account {
  border: 0 !important;
  padding: 15px !important;
}
#mastodon .status:not(.account),
.public-layout .status:not(.account),
.modal-layout .status:not(.account),
#mastodon .focusable > .account:not(.account),
.public-layout .focusable > .account:not(.account),
.modal-layout .focusable > .account:not(.account) {
  padding-inline-start: var(--status-left-padding) !important;
}
#mastodon .status.account .account__avatar-wrapper,
.public-layout .status.account .account__avatar-wrapper,
.modal-layout .status.account .account__avatar-wrapper,
#mastodon .focusable > .account.account .account__avatar-wrapper,
.public-layout .focusable > .account.account .account__avatar-wrapper,
.modal-layout .focusable > .account.account .account__avatar-wrapper {
  margin-left: 10px;
}
#mastodon .status__prepend,
.public-layout .status__prepend,
.modal-layout .status__prepend,
#mastodon .notification__message,
.public-layout .notification__message,
.modal-layout .notification__message {
  padding-top: 15px !important;
  padding-bottom: 0 !important;
  margin-inline-start: var(--status-left-padding);
  margin-bottom: -10px !important;
  z-index: 2;
}
#mastodon .account__avatar-overlay-base,
.public-layout .account__avatar-overlay-base,
.modal-layout .account__avatar-overlay-base {
  width: 100%;
  height: 100%;
  background-size: cover;
  border-radius: var(--radius);
}
#mastodon .account__avatar-overlay-overlay,
.public-layout .account__avatar-overlay-overlay,
.modal-layout .account__avatar-overlay-overlay {
  border-radius: var(--radius-round);
  bottom: -10%;
  right: -10%;
}
#mastodon .status__info,
.public-layout .status__info,
.modal-layout .status__info {
  margin-top: 5px;
}
#mastodon .status__info .status__avatar,
.public-layout .status__info .status__avatar,
.modal-layout .status__info .status__avatar {
  top: 15px;
  left: 15px !important;
  right: 15px;
  z-index: 2;
}
#mastodon .status__info .display-name strong,
.public-layout .status__info .display-name strong,
.modal-layout .status__info .display-name strong {
  font-weight: 600;
}
#mastodon .status__content,
.public-layout .status__content,
.modal-layout .status__content {
  min-height: 1.4em;
  padding-top: 2px;
  margin-inline: -100px;
  padding-inline: 100px;
  text-align: unset !important;
}
#mastodon .status__content.status__content--with-spoiler > p:first-child,
.public-layout .status__content.status__content--with-spoiler > p:first-child,
.modal-layout .status__content.status__content--with-spoiler > p:first-child {
  margin-bottom: 0;
}
.rtl #mastodon .status__content,
.rtl .public-layout .status__content,
.rtl .modal-layout .status__content {
  text-align: right !important;
}
#mastodon .status__content .status__content__text > br:first-child,
.public-layout .status__content .status__content__text > br:first-child,
.modal-layout .status__content .status__content__text > br:first-child {
  display: none !important;
}
#mastodon .status__content__spoiler-link,
.public-layout .status__content__spoiler-link,
.modal-layout .status__content__spoiler-link {
  display: block;
  width: unset;
  padding: 1em;
  color: inherit;
  font-weight: 700;
  background: none;
  margin-inline-start: -100px !important;
  padding-inline-start: 100px !important;
  width: calc(100% + 150px);
  text-align: unset;
  font-size: 0.8em;
}
#mastodon .status__content__spoiler-link:hover,
.public-layout .status__content__spoiler-link:hover,
.modal-layout .status__content__spoiler-link:hover,
#mastodon .status__content__spoiler-link:focus,
.public-layout .status__content__spoiler-link:focus,
.modal-layout .status__content__spoiler-link:focus {
  background-color: var(--hover-color);
}
#mastodon .media-gallery,
.public-layout .media-gallery,
.modal-layout .media-gallery,
#mastodon .video-player,
.public-layout .video-player,
.modal-layout .video-player,
#mastodon .status-card.horizontal.interactive,
.public-layout .status-card.horizontal.interactive,
.modal-layout .status-card.horizontal.interactive,
#mastodon .status-card,
.public-layout .status-card,
.modal-layout .status-card,
#mastodon .audio-player,
.public-layout .audio-player,
.modal-layout .audio-player,
#mastodon .picture-in-picture-placeholder,
.public-layout .picture-in-picture-placeholder,
.modal-layout .picture-in-picture-placeholder {
  margin-inline-start: calc(15px - var(--status-left-padding));
  width: unset !important;
  box-shadow: var(--shadow-low);
  border-radius: var(--radius);
  margin-block: 15px !important;
  animation: scaleIn 0.4s;
  max-width: unset !important;
  max-height: 80vh !important;
  direction: unset;
}
#mastodon .media-gallery.status-card,
.public-layout .media-gallery.status-card,
.modal-layout .media-gallery.status-card,
#mastodon .video-player.status-card,
.public-layout .video-player.status-card,
.modal-layout .video-player.status-card,
#mastodon .status-card.horizontal.interactive.status-card,
.public-layout .status-card.horizontal.interactive.status-card,
.modal-layout .status-card.horizontal.interactive.status-card,
#mastodon .status-card.status-card,
.public-layout .status-card.status-card,
.modal-layout .status-card.status-card,
#mastodon .audio-player.status-card,
.public-layout .audio-player.status-card,
.modal-layout .audio-player.status-card,
#mastodon .picture-in-picture-placeholder.status-card,
.public-layout .picture-in-picture-placeholder.status-card,
.modal-layout .picture-in-picture-placeholder.status-card {
  box-shadow: var(--shadow);
  box-shadow: none;
}
#mastodon .media-gallery.status-card .status-card__content,
.public-layout .media-gallery.status-card .status-card__content,
.modal-layout .media-gallery.status-card .status-card__content,
#mastodon .video-player.status-card .status-card__content,
.public-layout .video-player.status-card .status-card__content,
.modal-layout .video-player.status-card .status-card__content,
#mastodon .status-card.horizontal.interactive.status-card .status-card__content,
.public-layout .status-card.horizontal.interactive.status-card .status-card__content,
.modal-layout .status-card.horizontal.interactive.status-card .status-card__content,
#mastodon .status-card.status-card .status-card__content,
.public-layout .status-card.status-card .status-card__content,
.modal-layout .status-card.status-card .status-card__content,
#mastodon .audio-player.status-card .status-card__content,
.public-layout .audio-player.status-card .status-card__content,
.modal-layout .audio-player.status-card .status-card__content,
#mastodon .picture-in-picture-placeholder.status-card .status-card__content,
.public-layout .picture-in-picture-placeholder.status-card .status-card__content,
.modal-layout .picture-in-picture-placeholder.status-card .status-card__content {
  padding-inline: 12px;
}
#mastodon .media-gallery.status-card:hover,
.public-layout .media-gallery.status-card:hover,
.modal-layout .media-gallery.status-card:hover,
#mastodon .video-player.status-card:hover,
.public-layout .video-player.status-card:hover,
.modal-layout .video-player.status-card:hover,
#mastodon .status-card.horizontal.interactive.status-card:hover,
.public-layout .status-card.horizontal.interactive.status-card:hover,
.modal-layout .status-card.horizontal.interactive.status-card:hover,
#mastodon .status-card.status-card:hover,
.public-layout .status-card.status-card:hover,
.modal-layout .status-card.status-card:hover,
#mastodon .audio-player.status-card:hover,
.public-layout .audio-player.status-card:hover,
.modal-layout .audio-player.status-card:hover,
#mastodon .picture-in-picture-placeholder.status-card:hover,
.public-layout .picture-in-picture-placeholder.status-card:hover,
.modal-layout .picture-in-picture-placeholder.status-card:hover {
  background-color: var(--hover-color);
}
#mastodon .media-gallery__item:not([style*="50%"]),
.public-layout .media-gallery__item:not([style*="50%"]),
.modal-layout .media-gallery__item:not([style*="50%"]) {
  border-radius: var(--radius);
}
#mastodon .detailed-status,
.public-layout .detailed-status,
.modal-layout .detailed-status {
  padding: 15px !important;
  padding-bottom: 5px !important;
}
#mastodon div:empty + div > .detailed-status__wrapper,
.public-layout div:empty + div > .detailed-status__wrapper,
.modal-layout div:empty + div > .detailed-status__wrapper {
  margin-top: 0 !important;
}
#mastodon .detailed-status__wrapper,
.public-layout .detailed-status__wrapper,
.modal-layout .detailed-status__wrapper,
#mastodon .detailed-status,
.public-layout .detailed-status,
.modal-layout .detailed-status,
#mastodon .picture-in-picture,
.public-layout .picture-in-picture,
.modal-layout .picture-in-picture {
  box-shadow: var(--shadow);
}
#mastodon .detailed-status__wrapper.detailed-status__wrapper,
.public-layout .detailed-status__wrapper.detailed-status__wrapper,
.modal-layout .detailed-status__wrapper.detailed-status__wrapper,
#mastodon .detailed-status.detailed-status__wrapper,
.public-layout .detailed-status.detailed-status__wrapper,
.modal-layout .detailed-status.detailed-status__wrapper,
#mastodon .picture-in-picture.detailed-status__wrapper,
.public-layout .picture-in-picture.detailed-status__wrapper,
.modal-layout .picture-in-picture.detailed-status__wrapper {
  margin-block: 15px;
}
#mastodon .detailed-status__wrapper .media-gallery,
.public-layout .detailed-status__wrapper .media-gallery,
.modal-layout .detailed-status__wrapper .media-gallery,
#mastodon .detailed-status .media-gallery,
.public-layout .detailed-status .media-gallery,
.modal-layout .detailed-status .media-gallery,
#mastodon .picture-in-picture .media-gallery,
.public-layout .picture-in-picture .media-gallery,
.modal-layout .picture-in-picture .media-gallery,
#mastodon .detailed-status__wrapper .video-player,
.public-layout .detailed-status__wrapper .video-player,
.modal-layout .detailed-status__wrapper .video-player,
#mastodon .detailed-status .video-player,
.public-layout .detailed-status .video-player,
.modal-layout .detailed-status .video-player,
#mastodon .picture-in-picture .video-player,
.public-layout .picture-in-picture .video-player,
.modal-layout .picture-in-picture .video-player,
#mastodon .detailed-status__wrapper .status-card.horizontal.interactive,
.public-layout .detailed-status__wrapper .status-card.horizontal.interactive,
.modal-layout .detailed-status__wrapper .status-card.horizontal.interactive,
#mastodon .detailed-status .status-card.horizontal.interactive,
.public-layout .detailed-status .status-card.horizontal.interactive,
.modal-layout .detailed-status .status-card.horizontal.interactive,
#mastodon .picture-in-picture .status-card.horizontal.interactive,
.public-layout .picture-in-picture .status-card.horizontal.interactive,
.modal-layout .picture-in-picture .status-card.horizontal.interactive,
#mastodon .detailed-status__wrapper .status-card,
.public-layout .detailed-status__wrapper .status-card,
.modal-layout .detailed-status__wrapper .status-card,
#mastodon .detailed-status .status-card,
.public-layout .detailed-status .status-card,
.modal-layout .detailed-status .status-card,
#mastodon .picture-in-picture .status-card,
.public-layout .picture-in-picture .status-card,
.modal-layout .picture-in-picture .status-card,
#mastodon .detailed-status__wrapper .audio-player,
.public-layout .detailed-status__wrapper .audio-player,
.modal-layout .detailed-status__wrapper .audio-player,
#mastodon .detailed-status .audio-player,
.public-layout .detailed-status .audio-player,
.modal-layout .detailed-status .audio-player,
#mastodon .picture-in-picture .audio-player,
.public-layout .picture-in-picture .audio-player,
.modal-layout .picture-in-picture .audio-player,
#mastodon .detailed-status__wrapper .picture-in-picture-placeholder,
.public-layout .detailed-status__wrapper .picture-in-picture-placeholder,
.modal-layout .detailed-status__wrapper .picture-in-picture-placeholder,
#mastodon .detailed-status .picture-in-picture-placeholder,
.public-layout .detailed-status .picture-in-picture-placeholder,
.modal-layout .detailed-status .picture-in-picture-placeholder,
#mastodon .picture-in-picture .picture-in-picture-placeholder,
.public-layout .picture-in-picture .picture-in-picture-placeholder,
.modal-layout .picture-in-picture .picture-in-picture-placeholder {
  margin-inline: 0 !important;
  max-height: unset !important;
}
#mastodon .detailed-status__wrapper .status__content,
.public-layout .detailed-status__wrapper .status__content,
.modal-layout .detailed-status__wrapper .status__content,
#mastodon .detailed-status .status__content,
.public-layout .detailed-status .status__content,
.modal-layout .detailed-status .status__content,
#mastodon .picture-in-picture .status__content,
.public-layout .picture-in-picture .status__content,
.modal-layout .picture-in-picture .status__content {
  min-height: unset !important;
}
#mastodon .picture-in-picture .picture-in-picture__header,
.public-layout .picture-in-picture .picture-in-picture__header,
.modal-layout .picture-in-picture .picture-in-picture__header {
  border-radius: var(--radius) var(--radius) 0 0;
}
#mastodon .picture-in-picture .media-gallery,
.public-layout .picture-in-picture .media-gallery,
.modal-layout .picture-in-picture .media-gallery,
#mastodon .picture-in-picture .video-player,
.public-layout .picture-in-picture .video-player,
.modal-layout .picture-in-picture .video-player,
#mastodon .picture-in-picture .status-card.horizontal.interactive,
.public-layout .picture-in-picture .status-card.horizontal.interactive,
.modal-layout .picture-in-picture .status-card.horizontal.interactive,
#mastodon .picture-in-picture .status-card,
.public-layout .picture-in-picture .status-card,
.modal-layout .picture-in-picture .status-card,
#mastodon .picture-in-picture .audio-player,
.public-layout .picture-in-picture .audio-player,
.modal-layout .picture-in-picture .audio-player,
#mastodon .picture-in-picture .picture-in-picture-placeholder,
.public-layout .picture-in-picture .picture-in-picture-placeholder,
.modal-layout .picture-in-picture .picture-in-picture-placeholder {
  margin: 0 !important;
  border-radius: 0 !important;
}
#mastodon .picture-in-picture .picture-in-picture__footer,
.public-layout .picture-in-picture .picture-in-picture__footer,
.modal-layout .picture-in-picture .picture-in-picture__footer {
  border-radius: 0 0 var(--radius) var(--radius);
}
#mastodon .status__action-bar .icon-button,
.public-layout .status__action-bar .icon-button,
.modal-layout .status__action-bar .icon-button,
#mastodon .detailed-status__action-bar .icon-button,
.public-layout .detailed-status__action-bar .icon-button,
.modal-layout .detailed-status__action-bar .icon-button,
#mastodon .picture-in-picture__footer .icon-button,
.public-layout .picture-in-picture__footer .icon-button,
.modal-layout .picture-in-picture__footer .icon-button {
  display: inline-flex;
  align-items: center;
  width: unset !important;
  padding: 0.3em 0.5em !important;
  height: unset !important;
  flex: 0 1 auto !important;
  min-width: 0;
  border-radius: var(--radius);
}
#mastodon .status__action-bar .icon-button:last-child,
.public-layout .status__action-bar .icon-button:last-child,
.modal-layout .status__action-bar .icon-button:last-child,
#mastodon .detailed-status__action-bar .icon-button:last-child,
.public-layout .detailed-status__action-bar .icon-button:last-child,
.modal-layout .detailed-status__action-bar .icon-button:last-child,
#mastodon .picture-in-picture__footer .icon-button:last-child,
.public-layout .picture-in-picture__footer .icon-button:last-child,
.modal-layout .picture-in-picture__footer .icon-button:last-child {
  margin: 0 !important;
}
#mastodon .status__action-bar .icon-button[aria-label]::after,
.public-layout .status__action-bar .icon-button[aria-label]::after,
.modal-layout .status__action-bar .icon-button[aria-label]::after,
#mastodon .detailed-status__action-bar .icon-button[aria-label]::after,
.public-layout .detailed-status__action-bar .icon-button[aria-label]::after,
.modal-layout .detailed-status__action-bar .icon-button[aria-label]::after,
#mastodon .picture-in-picture__footer .icon-button[aria-label]::after,
.public-layout .picture-in-picture__footer .icon-button[aria-label]::after,
.modal-layout .picture-in-picture__footer .icon-button[aria-label]::after {
  all: unset;
  font-size: 0.7em;
  margin-inline-start: 0.5em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#mastodon .status__action-bar .status__action-bar-dropdown,
.public-layout .status__action-bar .status__action-bar-dropdown,
.modal-layout .status__action-bar .status__action-bar-dropdown,
#mastodon .detailed-status__action-bar .status__action-bar-dropdown,
.public-layout .detailed-status__action-bar .status__action-bar-dropdown,
.modal-layout .detailed-status__action-bar .status__action-bar-dropdown,
#mastodon .picture-in-picture__footer .status__action-bar-dropdown,
.public-layout .picture-in-picture__footer .status__action-bar-dropdown,
.modal-layout .picture-in-picture__footer .status__action-bar-dropdown,
#mastodon .status__action-bar .detailed-status__action-bar-dropdown,
.public-layout .status__action-bar .detailed-status__action-bar-dropdown,
.modal-layout .status__action-bar .detailed-status__action-bar-dropdown,
#mastodon .detailed-status__action-bar .detailed-status__action-bar-dropdown,
.public-layout .detailed-status__action-bar .detailed-status__action-bar-dropdown,
.modal-layout .detailed-status__action-bar .detailed-status__action-bar-dropdown,
#mastodon .picture-in-picture__footer .detailed-status__action-bar-dropdown,
.public-layout .picture-in-picture__footer .detailed-status__action-bar-dropdown,
.modal-layout .picture-in-picture__footer .detailed-status__action-bar-dropdown,
#mastodon .status__action-bar .detailed-status__button,
.public-layout .status__action-bar .detailed-status__button,
.modal-layout .status__action-bar .detailed-status__button,
#mastodon .detailed-status__action-bar .detailed-status__button,
.public-layout .detailed-status__action-bar .detailed-status__button,
.modal-layout .detailed-status__action-bar .detailed-status__button,
#mastodon .picture-in-picture__footer .detailed-status__button,
.public-layout .picture-in-picture__footer .detailed-status__button,
.modal-layout .picture-in-picture__footer .detailed-status__button {
  display: contents;
}
#mastodon .status__action-bar.detailed-status__action-bar,
.public-layout .status__action-bar.detailed-status__action-bar,
.modal-layout .status__action-bar.detailed-status__action-bar,
#mastodon .detailed-status__action-bar.detailed-status__action-bar,
.public-layout .detailed-status__action-bar.detailed-status__action-bar,
.modal-layout .detailed-status__action-bar.detailed-status__action-bar,
#mastodon .picture-in-picture__footer.detailed-status__action-bar,
.public-layout .picture-in-picture__footer.detailed-status__action-bar,
.modal-layout .picture-in-picture__footer.detailed-status__action-bar,
#mastodon .status__action-bar.picture-in-picture__footer,
.public-layout .status__action-bar.picture-in-picture__footer,
.modal-layout .status__action-bar.picture-in-picture__footer,
#mastodon .detailed-status__action-bar.picture-in-picture__footer,
.public-layout .detailed-status__action-bar.picture-in-picture__footer,
.modal-layout .detailed-status__action-bar.picture-in-picture__footer,
#mastodon .picture-in-picture__footer.picture-in-picture__footer,
.public-layout .picture-in-picture__footer.picture-in-picture__footer,
.modal-layout .picture-in-picture__footer.picture-in-picture__footer {
  padding-right: 15px !important;
  padding-left: 15px !important;
}
#mastodon .status__action-bar.detailed-status__action-bar .icon-button,
.public-layout .status__action-bar.detailed-status__action-bar .icon-button,
.modal-layout .status__action-bar.detailed-status__action-bar .icon-button,
#mastodon .detailed-status__action-bar.detailed-status__action-bar .icon-button,
.public-layout .detailed-status__action-bar.detailed-status__action-bar .icon-button,
.modal-layout .detailed-status__action-bar.detailed-status__action-bar .icon-button,
#mastodon .picture-in-picture__footer.detailed-status__action-bar .icon-button,
.public-layout .picture-in-picture__footer.detailed-status__action-bar .icon-button,
.modal-layout .picture-in-picture__footer.detailed-status__action-bar .icon-button,
#mastodon .status__action-bar.picture-in-picture__footer .icon-button,
.public-layout .status__action-bar.picture-in-picture__footer .icon-button,
.modal-layout .status__action-bar.picture-in-picture__footer .icon-button,
#mastodon .detailed-status__action-bar.picture-in-picture__footer .icon-button,
.public-layout .detailed-status__action-bar.picture-in-picture__footer .icon-button,
.modal-layout .detailed-status__action-bar.picture-in-picture__footer .icon-button,
#mastodon .picture-in-picture__footer.picture-in-picture__footer .icon-button,
.public-layout .picture-in-picture__footer.picture-in-picture__footer .icon-button,
.modal-layout .picture-in-picture__footer.picture-in-picture__footer .icon-button {
  flex-grow: 1 !important;
  justify-content: center;
}
#mastodon .status__action-bar.picture-in-picture__footer .icon-button::after,
.public-layout .status__action-bar.picture-in-picture__footer .icon-button::after,
.modal-layout .status__action-bar.picture-in-picture__footer .icon-button::after,
#mastodon .detailed-status__action-bar.picture-in-picture__footer .icon-button::after,
.public-layout .detailed-status__action-bar.picture-in-picture__footer .icon-button::after,
.modal-layout .detailed-status__action-bar.picture-in-picture__footer .icon-button::after,
#mastodon .picture-in-picture__footer.picture-in-picture__footer .icon-button::after,
.public-layout .picture-in-picture__footer.picture-in-picture__footer .icon-button::after,
.modal-layout .picture-in-picture__footer.picture-in-picture__footer .icon-button::after {
  content: unset !important;
}
#mastodon .tabs-bar__wrapper,
.public-layout .tabs-bar__wrapper,
.modal-layout .tabs-bar__wrapper,
#mastodon .column-back-button--slim .column-back-button,
.public-layout .column-back-button--slim .column-back-button,
.modal-layout .column-back-button--slim .column-back-button {
  transition: margin 0.2s cubic-bezier(0, 0, 0, 1.1);
}
.column-header {
  font-weight: 600;
}
.column-header,
.column-header button {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.column-header ~ .scrollable {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
@media (min-width: 1191px) {
  #mastodon .tabs-bar__wrapper,
  .public-layout .tabs-bar__wrapper,
  .modal-layout .tabs-bar__wrapper,
  #mastodon .column-back-button--slim .column-back-button,
  .public-layout .column-back-button--slim .column-back-button,
  .modal-layout .column-back-button--slim .column-back-button {
    position: fixed;
    width: 285px;
    margin-inline-start: 600px;
    top: 0 !important;
    left: unset !important;
    right: unset !important;
    min-height: 48px !important;
    margin-top: 30px;
    overflow: clip;
    border-radius: var(--radius) var(--radius) !important;
    box-shadow: 0 12px 12px -12px rgba(0,0,0,0.1);
  }
  #mastodon .tabs-bar__wrapper:not(.column-back-button),
  .public-layout .tabs-bar__wrapper:not(.column-back-button),
  .modal-layout .tabs-bar__wrapper:not(.column-back-button),
  #mastodon .column-back-button--slim .column-back-button:not(.column-back-button),
  .public-layout .column-back-button--slim .column-back-button:not(.column-back-button),
  .modal-layout .column-back-button--slim .column-back-button:not(.column-back-button) {
    padding-top: 0;
  }
  #mastodon .tabs-bar__wrapper .column-header,
  .public-layout .tabs-bar__wrapper .column-header,
  .modal-layout .tabs-bar__wrapper .column-header,
  #mastodon .column-back-button--slim .column-back-button .column-header,
  .public-layout .column-back-button--slim .column-back-button .column-header,
  .modal-layout .column-back-button--slim .column-back-button .column-header,
  #mastodon .tabs-bar__wrapper .column-header__buttons button,
  .public-layout .tabs-bar__wrapper .column-header__buttons button,
  .modal-layout .tabs-bar__wrapper .column-header__buttons button,
  #mastodon .column-back-button--slim .column-back-button .column-header__buttons button,
  .public-layout .column-back-button--slim .column-back-button .column-header__buttons button,
  .modal-layout .column-back-button--slim .column-back-button .column-header__buttons button {
    background: none !important;
  }
  #mastodon .tabs-bar__wrapper .announcements,
  .public-layout .tabs-bar__wrapper .announcements,
  .modal-layout .tabs-bar__wrapper .announcements,
  #mastodon .column-back-button--slim .column-back-button .announcements,
  .public-layout .column-back-button--slim .column-back-button .announcements,
  .modal-layout .column-back-button--slim .column-back-button .announcements {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
  #mastodon .tabs-bar__wrapper .announcements__container,
  .public-layout .tabs-bar__wrapper .announcements__container,
  .modal-layout .tabs-bar__wrapper .announcements__container,
  #mastodon .column-back-button--slim .column-back-button .announcements__container,
  .public-layout .column-back-button--slim .column-back-button .announcements__container,
  .modal-layout .column-back-button--slim .column-back-button .announcements__container {
    width: 100% !important;
  }
  #mastodon .tabs-bar__wrapper .announcements__mastodon,
  .public-layout .tabs-bar__wrapper .announcements__mastodon,
  .modal-layout .tabs-bar__wrapper .announcements__mastodon,
  #mastodon .column-back-button--slim .column-back-button .announcements__mastodon,
  .public-layout .column-back-button--slim .column-back-button .announcements__mastodon,
  .modal-layout .column-back-button--slim .column-back-button .announcements__mastodon {
    margin-inline-start: 10px;
    z-index: -1;
    position: relative;
  }
}
@media (min-width: 895px) and (max-width: 1191px) {
  #mastodon .tabs-bar__wrapper.column-back-button,
  .public-layout .tabs-bar__wrapper.column-back-button,
  .modal-layout .tabs-bar__wrapper.column-back-button,
  #mastodon .column-back-button--slim .column-back-button.column-back-button,
  .public-layout .column-back-button--slim .column-back-button.column-back-button,
  .modal-layout .column-back-button--slim .column-back-button.column-back-button {
    border-radius: 0 !important;
  }
  #mastodon .tabs-bar__wrapper:not(.column-back-button),
  .public-layout .tabs-bar__wrapper:not(.column-back-button),
  .modal-layout .tabs-bar__wrapper:not(.column-back-button),
  #mastodon .column-back-button--slim .column-back-button:not(.column-back-button),
  .public-layout .column-back-button--slim .column-back-button:not(.column-back-button),
  .modal-layout .column-back-button--slim .column-back-button:not(.column-back-button) {
    padding-top: 0;
  }
  #mastodon .tabs-bar__wrapper:not(.column-back-button) .tabs-bar,
  .public-layout .tabs-bar__wrapper:not(.column-back-button) .tabs-bar,
  .modal-layout .tabs-bar__wrapper:not(.column-back-button) .tabs-bar,
  #mastodon .column-back-button--slim .column-back-button:not(.column-back-button) .tabs-bar,
  .public-layout .column-back-button--slim .column-back-button:not(.column-back-button) .tabs-bar,
  .modal-layout .column-back-button--slim .column-back-button:not(.column-back-button) .tabs-bar {
    margin-bottom: 0;
    border: 0 !important;
  }
  #mastodon .tabs-bar__wrapper:not(.column-back-button) #tabs-bar__portal,
  .public-layout .tabs-bar__wrapper:not(.column-back-button) #tabs-bar__portal,
  .modal-layout .tabs-bar__wrapper:not(.column-back-button) #tabs-bar__portal,
  #mastodon .column-back-button--slim .column-back-button:not(.column-back-button) #tabs-bar__portal,
  .public-layout .column-back-button--slim .column-back-button:not(.column-back-button) #tabs-bar__portal,
  .modal-layout .column-back-button--slim .column-back-button:not(.column-back-button) #tabs-bar__portal {
    border-radius: 0 !important;
  }
  #mastodon .tabs-bar__wrapper:not(.column-back-button) #tabs-bar__portal .column-header,
  .public-layout .tabs-bar__wrapper:not(.column-back-button) #tabs-bar__portal .column-header,
  .modal-layout .tabs-bar__wrapper:not(.column-back-button) #tabs-bar__portal .column-header,
  #mastodon .column-back-button--slim .column-back-button:not(.column-back-button) #tabs-bar__portal .column-header,
  .public-layout .column-back-button--slim .column-back-button:not(.column-back-button) #tabs-bar__portal .column-header,
  .modal-layout .column-back-button--slim .column-back-button:not(.column-back-button) #tabs-bar__portal .column-header,
  #mastodon .tabs-bar__wrapper:not(.column-back-button) #tabs-bar__portal button,
  .public-layout .tabs-bar__wrapper:not(.column-back-button) #tabs-bar__portal button,
  .modal-layout .tabs-bar__wrapper:not(.column-back-button) #tabs-bar__portal button,
  #mastodon .column-back-button--slim .column-back-button:not(.column-back-button) #tabs-bar__portal button,
  .public-layout .column-back-button--slim .column-back-button:not(.column-back-button) #tabs-bar__portal button,
  .modal-layout .column-back-button--slim .column-back-button:not(.column-back-button) #tabs-bar__portal button {
    border-radius: 0 !important;
  }
  #mastodon .tabs-bar__wrapper:not(.column-back-button) ~ .columns-area .scrollable,
  .public-layout .tabs-bar__wrapper:not(.column-back-button) ~ .columns-area .scrollable,
  .modal-layout .tabs-bar__wrapper:not(.column-back-button) ~ .columns-area .scrollable,
  #mastodon .column-back-button--slim .column-back-button:not(.column-back-button) ~ .columns-area .scrollable,
  .public-layout .column-back-button--slim .column-back-button:not(.column-back-button) ~ .columns-area .scrollable,
  .modal-layout .column-back-button--slim .column-back-button:not(.column-back-button) ~ .columns-area .scrollable {
    border-radius: 0 !important;
  }
  #mastodon .columns-area__panels__main,
  #mastodon .columns-area__panels__main .columns-area {
    padding-top: 0 !important;
  }
  #mastodon .columns-area__panels__main > div,
  #mastodon .columns-area__panels__main .scrollable {
    border-radius: 0 !important;
  }
}
@media (min-width: 1191px) and (min-width: 1320px) {
  #mastodon .tabs-bar__wrapper,
  .public-layout .tabs-bar__wrapper,
  .modal-layout .tabs-bar__wrapper,
  #mastodon .column-back-button--slim .column-back-button,
  .public-layout .column-back-button--slim .column-back-button,
  .modal-layout .column-back-button--slim .column-back-button {
    margin-inline-start: calc(var(--tl-width) - 5px);
  }
}
#mastodon .column-header__collapsible-inner,
.public-layout .column-header__collapsible-inner,
.modal-layout .column-header__collapsible-inner {
  padding-bottom: 0 !important;
  overflow: hidden;
}
#mastodon .setting-toggle,
.public-layout .setting-toggle,
.modal-layout .setting-toggle {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  position: relative;
}
#mastodon .setting-toggle .setting-toggle__label,
.public-layout .setting-toggle .setting-toggle__label,
.modal-layout .setting-toggle .setting-toggle__label {
  margin-bottom: 0 !important;
  flex-grow: 1;
}
#mastodon .navigation-panel,
.public-layout .navigation-panel,
.modal-layout .navigation-panel {
  padding-top: 50px;
  padding-bottom: 20px;
  height: calc(100% - 30px);
  box-sizing: border-box;
}
#mastodon .navigation-panel .column-link,
.public-layout .navigation-panel .column-link,
.modal-layout .navigation-panel .column-link {
  flex-grow: 2 !important;
  display: flex !important;
  align-items: center !important;
  align-content: center;
  max-height: 3.3em;
  min-height: 2.7em !important;
  padding-block: 0;
  position: relative;
  box-sizing: border-box;
  opacity: 0.9;
  overflow: hidden;
}
#mastodon .navigation-panel .column-link .column-link__icon,
.public-layout .navigation-panel .column-link .column-link__icon,
.modal-layout .navigation-panel .column-link .column-link__icon,
.column-header__icon {
  margin-inline-end: 1em !important;
}
#mastodon .navigation-panel .column-link::before,
.public-layout .navigation-panel .column-link::before,
.modal-layout .navigation-panel .column-link::before {
  content: "" !important;
  position: absolute;
  border-radius: 100px !important;
  width: unset !important;
  height: unset !important;
  inset: 0px 0px !important;
  opacity: 0 !important;
  background-color: rgba(150,150,150,0.1);
  transition: opacity 0.2s;
}
#mastodon .navigation-panel .column-link.active,
.public-layout .navigation-panel .column-link.active,
.modal-layout .navigation-panel .column-link.active {
  opacity: 1 !important;
  font-weight: 600;
}
#mastodon .navigation-panel .column-link:hover:before,
.public-layout .navigation-panel .column-link:hover:before,
.modal-layout .navigation-panel .column-link:hover:before,
#mastodon .navigation-panel .column-link:focus:before,
.public-layout .navigation-panel .column-link:focus:before,
.modal-layout .navigation-panel .column-link:focus:before {
  opacity: 1 !important;
}
#mastodon .navigation-panel .column-link[href="/settings/preferences"],
.public-layout .navigation-panel .column-link[href="/settings/preferences"],
.modal-layout .navigation-panel .column-link[href="/settings/preferences"] {
  display: none !important;
}
#mastodon .navigation-panel .column-link[href="/web/lists"] + div hr,
.public-layout .navigation-panel .column-link[href="/web/lists"] + div hr,
.modal-layout .navigation-panel .column-link[href="/web/lists"] + div hr {
  display: none;
}
#mastodon .navigation-panel .column-link[href="/web/lists"] + div .column-link i,
.public-layout .navigation-panel .column-link[href="/web/lists"] + div .column-link i,
.modal-layout .navigation-panel .column-link[href="/web/lists"] + div .column-link i {
  opacity: 0.2;
}
#mastodon .getting-started__trends,
.public-layout .getting-started__trends,
.modal-layout .getting-started__trends {
  display: unset !important;
}
#mastodon .trends__item,
.public-layout .trends__item,
.modal-layout .trends__item {
  display: flex !important;
}
#mastodon .trends__item__name a::before,
.public-layout .trends__item__name a::before,
.modal-layout .trends__item__name a::before {
  content: "";
  position: absolute;
  inset: 0;
}
#mastodon .trends__item__current,
.public-layout .trends__item__current,
.modal-layout .trends__item__current {
  display: none;
}
#mastodon .trends__item__sparkline,
.public-layout .trends__item__sparkline,
.modal-layout .trends__item__sparkline {
  overflow: visible !important;
}
#mastodon .trends__item__sparkline svg,
.public-layout .trends__item__sparkline svg,
.modal-layout .trends__item__sparkline svg {
  overflow: visible !important;
}
#mastodon .trends__item__sparkline path:first-child,
.public-layout .trends__item__sparkline path:first-child,
.modal-layout .trends__item__sparkline path:first-child {
  filter: blur(10px);
}
#mastodon .trends__item__sparkline path:last-child,
.public-layout .trends__item__sparkline path:last-child,
.modal-layout .trends__item__sparkline path:last-child {
  mask: linear-gradient(to left, #000, #000, transparent);
}
#mastodon .getting-started,
.public-layout .getting-started,
.modal-layout .getting-started {
  padding: 5px 10px;
  border: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
#mastodon .getting-started .flex-spacer,
.public-layout .getting-started .flex-spacer,
.modal-layout .getting-started .flex-spacer {
  display: none;
}
#mastodon .getting-started .getting-started__wrapper,
.public-layout .getting-started .getting-started__wrapper,
.modal-layout .getting-started .getting-started__wrapper {
  background: none;
  height: unset !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
#mastodon .getting-started .getting-started__wrapper > *,
.public-layout .getting-started .getting-started__wrapper > *,
.modal-layout .getting-started .getting-started__wrapper > * {
  box-sizing: border-box;
}
#mastodon .getting-started .getting-started__wrapper .navigation-bar,
.public-layout .getting-started .getting-started__wrapper .navigation-bar,
.modal-layout .getting-started .getting-started__wrapper .navigation-bar {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0;
  margin: 20px;
  margin-bottom: 5px;
  position: relative;
}
#mastodon .getting-started .getting-started__wrapper .navigation-bar > *,
.public-layout .getting-started .getting-started__wrapper .navigation-bar > *,
.modal-layout .getting-started .getting-started__wrapper .navigation-bar > * {
  flex-grow: 0;
}
#mastodon .getting-started .getting-started__wrapper .navigation-bar > a,
.public-layout .getting-started .getting-started__wrapper .navigation-bar > a,
.modal-layout .getting-started .getting-started__wrapper .navigation-bar > a {
  flex-grow: 1;
  max-height: 126px;
  margin-bottom: 10px;
}
#mastodon .getting-started .getting-started__wrapper .navigation-bar .icon-button,
.public-layout .getting-started .getting-started__wrapper .navigation-bar .icon-button,
.modal-layout .getting-started .getting-started__wrapper .navigation-bar .icon-button {
  width: auto !important;
  height: auto !important;
  padding: 20px;
}
#mastodon .getting-started .getting-started__wrapper .navigation-bar .icon-button i,
.public-layout .getting-started .getting-started__wrapper .navigation-bar .icon-button i,
.modal-layout .getting-started .getting-started__wrapper .navigation-bar .icon-button i {
  font-size: 20px;
}
#mastodon .getting-started .getting-started__wrapper .navigation-bar__profile,
.public-layout .getting-started .getting-started__wrapper .navigation-bar__profile,
.modal-layout .getting-started .getting-started__wrapper .navigation-bar__profile {
  font-size: 1.2em;
  line-height: 1.4;
  margin: 10px 0;
}
#mastodon .getting-started .getting-started__wrapper .navigation-bar__profile .permalink,
.public-layout .getting-started .getting-started__wrapper .navigation-bar__profile .permalink,
.modal-layout .getting-started .getting-started__wrapper .navigation-bar__profile .permalink {
  font-size: 1.2em;
}
#mastodon .getting-started .getting-started__wrapper .navigation-bar__actions,
.public-layout .getting-started .getting-started__wrapper .navigation-bar__actions,
.modal-layout .getting-started .getting-started__wrapper .navigation-bar__actions {
  position: absolute;
  right: 0;
  bottom: 10px;
  width: auto !important;
}
#mastodon .getting-started .getting-started__wrapper .account__avatar,
.public-layout .getting-started .getting-started__wrapper .account__avatar,
.modal-layout .getting-started .getting-started__wrapper .account__avatar {
  width: 126px !important;
  min-height: 100%;
  flex-grow: 1;
  background-size: contain !important;
  background-position: left;
  border-radius: 0 !important;
}
.rtl #mastodon .getting-started .getting-started__wrapper .account__avatar,
.rtl .public-layout .getting-started .getting-started__wrapper .account__avatar,
.rtl .modal-layout .getting-started .getting-started__wrapper .account__avatar {
  background-position: right !important;
}
#mastodon .getting-started .getting-started__wrapper .account__avatar::before,
.public-layout .getting-started .getting-started__wrapper .account__avatar::before,
.modal-layout .getting-started .getting-started__wrapper .account__avatar::before {
  content: "";
  background-image: inherit;
  position: absolute;
  background-size: cover;
  background-position: center;
  height: calc(100% + 100px);
  width: 200%;
  border-bottom-right-radius: 100%;
  transform: translate(-40%, -20%);
  filter: blur(100px);
  opacity: 0.2;
}
.rtl #mastodon .getting-started .getting-started__wrapper .account__avatar::before,
.rtl .public-layout .getting-started .getting-started__wrapper .account__avatar::before,
.rtl .modal-layout .getting-started .getting-started__wrapper .account__avatar::before {
  transform: translate(40%, -20%);
}
#mastodon .getting-started .getting-started__wrapper > *,
.public-layout .getting-started .getting-started__wrapper > *,
.modal-layout .getting-started .getting-started__wrapper > * {
  border: 0 !important;
  padding: 20px;
  background: none;
}
#mastodon .getting-started .getting-started__footer,
.public-layout .getting-started .getting-started__footer,
.modal-layout .getting-started .getting-started__footer {
  padding-inline: 20px;
}
#mastodon .getting-started .getting-started__footer a span,
.public-layout .getting-started .getting-started__footer a span,
.modal-layout .getting-started .getting-started__footer a span {
  font-size: 1.1em !important;
  line-height: 2;
}
#mastodon.public-layout .container:nth-child(2) > .grid .column-1,
.public-layout.public-layout .container:nth-child(2) > .grid .column-1,
.modal-layout.public-layout .container:nth-child(2) > .grid .column-1 {
  position: sticky;
  align-self: flex-end;
  bottom: 0;
  min-height: calc(100vh - 80px);
}
#mastodon.public-layout .container:nth-child(2) > .grid .column-1 > *,
.public-layout.public-layout .container:nth-child(2) > .grid .column-1 > *,
.modal-layout.public-layout .container:nth-child(2) > .grid .column-1 > * {
  border-radius: var(--radius);
  overflow: hidden;
  border: 0 !important;
}
#mastodon.public-layout .container:nth-child(2) > .grid .column-1 a,
.public-layout.public-layout .container:nth-child(2) > .grid .column-1 a,
.modal-layout.public-layout .container:nth-child(2) > .grid .column-1 a,
#mastodon.public-layout .container:nth-child(2) > .grid .column-1 .hero-widget__text,
.public-layout.public-layout .container:nth-child(2) > .grid .column-1 .hero-widget__text,
.modal-layout.public-layout .container:nth-child(2) > .grid .column-1 .hero-widget__text {
  border: 0 !important;
}
@media (min-width: 416px) {
  #mastodon.public-layout .grid,
  .public-layout.public-layout .grid,
  .modal-layout.public-layout .grid,
  #mastodon.public-layout .grid-4,
  .public-layout.public-layout .grid-4,
  .modal-layout.public-layout .grid-4 {
    gap: 15px;
  }
  #mastodon.public-layout .grid a,
  .public-layout.public-layout .grid a,
  .modal-layout.public-layout .grid a,
  #mastodon.public-layout .grid-4 a,
  .public-layout.public-layout .grid-4 a,
  .modal-layout.public-layout .grid-4 a {
    max-width: 100%;
    box-sizing: border-box;
  }
  #mastodon.public-layout .row__mascot,
  .public-layout.public-layout .row__mascot,
  .modal-layout.public-layout .row__mascot {
    width: 0;
  }
  #mastodon.public-layout .row__mascot img,
  .public-layout.public-layout .row__mascot img,
  .modal-layout.public-layout .row__mascot img {
    max-width: 100%;
    object-fit: contain;
    object-position: bottom;
  }
  #mastodon.public-layout .header,
  .public-layout.public-layout .header,
  .modal-layout.public-layout .header {
    border-radius: var(--radius);
    margin: 15px 0;
  }
  #mastodon.public-layout .public-account-header,
  .public-layout.public-layout .public-account-header,
  .modal-layout.public-layout .public-account-header {
    border-radius: var(--radius);
    border: 0 !important;
  }
  #mastodon.public-layout .public-account-header:not(.public-account-header--no-bar),
  .public-layout.public-layout .public-account-header:not(.public-account-header--no-bar),
  .modal-layout.public-layout .public-account-header:not(.public-account-header--no-bar) {
    margin-bottom: 15px;
  }
  #mastodon.public-layout .public-account-header .public-account-header__bar::before,
  .public-layout.public-layout .public-account-header .public-account-header__bar::before,
  .modal-layout.public-layout .public-account-header .public-account-header__bar::before {
    border: 0;
  }
  #mastodon.public-layout .account__section-headline,
  .public-layout.public-layout .account__section-headline,
  .modal-layout.public-layout .account__section-headline {
    border-radius: var(--radius) var(--radius) 0 0;
    border: 0;
  }
  #mastodon.public-layout .account__section-headline + .activity-stream > .entry:first-child,
  .public-layout.public-layout .account__section-headline + .activity-stream > .entry:first-child,
  .modal-layout.public-layout .account__section-headline + .activity-stream > .entry:first-child {
    border-radius: 0 !important;
  }
  #mastodon.public-layout .table-of-contents,
  .public-layout.public-layout .table-of-contents,
  .modal-layout.public-layout .table-of-contents {
    max-height: calc(100vh - 20px);
    min-height: unset !important;
    overflow-y: scroll !important;
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 10px;
  }
}
@media (max-width: 600px) {
  #mastodon.public-layout .public-account-header__bar .avatar,
  .public-layout.public-layout .public-account-header__bar .avatar,
  .modal-layout.public-layout .public-account-header__bar .avatar {
    width: 100px !important;
    height: 100px !important;
    margin-top: -50px;
  }
  #mastodon.public-layout .public-account-header__bar .public-account-header__tabs,
  .public-layout.public-layout .public-account-header__bar .public-account-header__tabs,
  .modal-layout.public-layout .public-account-header__bar .public-account-header__tabs {
    border: 0 !important;
  }
  #mastodon.public-layout .public-account-header__bar .account__header__fields,
  .public-layout.public-layout .public-account-header__bar .account__header__fields,
  .modal-layout.public-layout .public-account-header__bar .account__header__fields {
    border: 0 !important;
    padding-top: 0 !important;
  }
}
@media (max-width: 416px) {
  #mastodon.public-layout .contact-widget,
  .public-layout.public-layout .contact-widget,
  .modal-layout.public-layout .contact-widget {
    padding: 0 10px;
  }
  #mastodon.public-layout .grid-4 > div:not(.column-4),
  .public-layout.public-layout .grid-4 > div:not(.column-4),
  .modal-layout.public-layout .grid-4 > div:not(.column-4) {
    position: relative;
    z-index: 2;
  }
  #mastodon.public-layout .grid-4 > div:not(.column-4).column-2,
  .public-layout.public-layout .grid-4 > div:not(.column-4).column-2,
  .modal-layout.public-layout .grid-4 > div:not(.column-4).column-2 {
    padding-block: 20px;
  }
  #mastodon.public-layout .table-of-contents,
  .public-layout.public-layout .table-of-contents,
  .modal-layout.public-layout .table-of-contents {
    max-height: 70vh;
    max-content: min-content;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 30px;
    box-sizing: border-box;
    overflow-x: scroll !important;
    scroll-snap-type: x mandatory;
    scroll-padding-inline-start: 30px;
    gap: 20px 30px;
    position: relative;
    background: none !important;
    border: 0 !important;
    scroll-behavior: smooth;
  }
  #mastodon.public-layout .table-of-contents > li,
  .public-layout.public-layout .table-of-contents > li,
  .modal-layout.public-layout .table-of-contents > li {
    width: 70%;
    max-height: 80%;
    overflow-y: auto;
    border: 1px solid var(--border-color);
    border-radius: var(--radius);
    scroll-snap-align: start;
    box-shadow: var(--shadow);
    transition: background-color 0.2s, box-shadow 0.2s;
  }
  #mastodon.public-layout .table-of-contents > li:hover,
  .public-layout.public-layout .table-of-contents > li:hover,
  .modal-layout.public-layout .table-of-contents > li:hover,
  #mastodon.public-layout .table-of-contents > li:focus-within,
  .public-layout.public-layout .table-of-contents > li:focus-within,
  .modal-layout.public-layout .table-of-contents > li:focus-within {
    background-color: var(--hover-color);
  }
  #mastodon.public-layout .table-of-contents > li ul,
  .public-layout.public-layout .table-of-contents > li ul,
  .modal-layout.public-layout .table-of-contents > li ul {
    border: 0 !important;
  }
  #mastodon.public-layout .table-of-contents > li > a,
  .public-layout.public-layout .table-of-contents > li > a,
  .modal-layout.public-layout .table-of-contents > li > a {
    border: 0 !important;
  }
  #mastodon.public-layout .table-of-contents > li:last-child,
  .public-layout.public-layout .table-of-contents > li:last-child,
  .modal-layout.public-layout .table-of-contents > li:last-child {
    margin-inline-end: 70%;
  }
}
#mastodon.public-layout .account-card,
.public-layout.public-layout .account-card,
.modal-layout.public-layout .account-card,
#mastodon.public-layout .card > a,
.public-layout.public-layout .card > a,
.modal-layout.public-layout .card > a,
#mastodon.public-layout .page-header,
.public-layout.public-layout .page-header,
.modal-layout.public-layout .page-header,
#mastodon.public-layout .statuses-grid__item,
.public-layout.public-layout .statuses-grid__item,
.modal-layout.public-layout .statuses-grid__item,
#mastodon.public-layout .box-widget,
.public-layout.public-layout .box-widget,
.modal-layout.public-layout .box-widget,
#mastodon.public-layout .directory__tag > a,
.public-layout.public-layout .directory__tag > a,
.modal-layout.public-layout .directory__tag > a,
#mastodon.public-layout .hero-widget,
.public-layout.public-layout .hero-widget,
.modal-layout.public-layout .hero-widget,
#mastodon.public-layout .landing-page__call-to-action,
.public-layout.public-layout .landing-page__call-to-action,
.modal-layout.public-layout .landing-page__call-to-action,
#mastodon.public-layout .table-of-contents,
.public-layout.public-layout .table-of-contents,
.modal-layout.public-layout .table-of-contents,
#mastodon.public-layout .rules-list,
.public-layout.public-layout .rules-list,
.modal-layout.public-layout .rules-list {
  border-radius: var(--radius);
  overflow: hidden;
}
#mastodon.public-layout .statuses-grid,
.public-layout.public-layout .statuses-grid,
.modal-layout.public-layout .statuses-grid {
  margin: auto;
}
#mastodon.public-layout .entry,
.public-layout.public-layout .entry,
.modal-layout.public-layout .entry,
#mastodon.public-layout .entry ~ .entry,
.public-layout.public-layout .entry ~ .entry,
.modal-layout.public-layout .entry ~ .entry {
  margin: 0;
}
#mastodon.public-layout .entry:not(:last-child):not(:first-child),
.public-layout.public-layout .entry:not(:last-child):not(:first-child),
.modal-layout.public-layout .entry:not(:last-child):not(:first-child),
#mastodon.public-layout .entry ~ .entry:not(:last-child):not(:first-child),
.public-layout.public-layout .entry ~ .entry:not(:last-child):not(:first-child),
.modal-layout.public-layout .entry ~ .entry:not(:last-child):not(:first-child) {
  border-radius: 0 !important;
}
#mastodon.public-layout .entry:not(:first-child:last-child):last-child,
.public-layout.public-layout .entry:not(:first-child:last-child):last-child,
.modal-layout.public-layout .entry:not(:first-child:last-child):last-child,
#mastodon.public-layout .entry ~ .entry:not(:first-child:last-child):last-child,
.public-layout.public-layout .entry ~ .entry:not(:first-child:last-child):last-child,
.modal-layout.public-layout .entry ~ .entry:not(:first-child:last-child):last-child {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
#mastodon.public-layout .entry:not(:first-child:last-child):first-child,
.public-layout.public-layout .entry:not(:first-child:last-child):first-child,
.modal-layout.public-layout .entry:not(:first-child:last-child):first-child,
#mastodon.public-layout .entry ~ .entry:not(:first-child:last-child):first-child,
.public-layout.public-layout .entry ~ .entry:not(:first-child:last-child):first-child,
.modal-layout.public-layout .entry ~ .entry:not(:first-child:last-child):first-child {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
#mastodon.public-layout .entry:last-child:before,
.public-layout.public-layout .entry:last-child:before,
.modal-layout.public-layout .entry:last-child:before {
  content: unset !important;
}
#mastodon.public-layout .entry::before,
.public-layout.public-layout .entry::before,
.modal-layout.public-layout .entry::before,
#mastodon.public-layout .entry::after,
.public-layout.public-layout .entry::after,
.modal-layout.public-layout .entry::after {
  bottom: 1px !important;
}
#mastodon.public-layout .status .status__content,
.public-layout.public-layout .status .status__content,
.modal-layout.public-layout .status .status__content {
  margin-top: 0;
}
#mastodon.public-layout .detailed-status,
.public-layout.public-layout .detailed-status,
.modal-layout.public-layout .detailed-status {
  padding: 20px !important;
  padding-bottom: 5px !important;
}
#mastodon.public-layout .detailed-status .status__content,
.public-layout.public-layout .detailed-status .status__content,
.modal-layout.public-layout .detailed-status .status__content {
  margin-top: 0;
}
#mastodon.public-layout .detailed-status .detailed-status__meta,
.public-layout.public-layout .detailed-status .detailed-status__meta,
.modal-layout.public-layout .detailed-status .detailed-status__meta {
  margin-top: 15px;
  margin-bottom: 10px;
}
#mastodon.public-layout .account__header__fields,
.public-layout.public-layout .account__header__fields,
.modal-layout.public-layout .account__header__fields {
  margin-bottom: 0 !important;
}
#mastodon.public-layout .footer .column-3,
.public-layout.public-layout .footer .column-3,
.modal-layout.public-layout .footer .column-3,
#mastodon.public-layout .footer .column-4,
.public-layout.public-layout .footer .column-4,
.modal-layout.public-layout .footer .column-4 {
  text-align: right;
}
#mastodon.modal-layout,
.public-layout.modal-layout,
.modal-layout.modal-layout {
  overflow: hidden;
}
#mastodon.modal-layout .container-alt,
.public-layout.modal-layout .container-alt,
.modal-layout.modal-layout .container-alt {
  background: inherit;
  height: 100%;
}
#mastodon.modal-layout .container-alt .public-layout,
.public-layout.modal-layout .container-alt .public-layout,
.modal-layout.modal-layout .container-alt .public-layout {
  padding: 0 !important;
}
#mastodon.modal-layout .container-alt .form-container,
.public-layout.modal-layout .container-alt .form-container,
.modal-layout.modal-layout .container-alt .form-container {
  max-width: 500px !important;
  padding: 0;
  background: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
#mastodon.modal-layout .container-alt .form-container h2,
.public-layout.modal-layout .container-alt .form-container h2,
.modal-layout.modal-layout .container-alt .form-container h2 {
  margin: 0;
  padding: 20px;
  font-weight: 600;
}
#mastodon.modal-layout .container-alt .form-container .follow-prompt,
.public-layout.modal-layout .container-alt .form-container .follow-prompt,
.modal-layout.modal-layout .container-alt .form-container .follow-prompt {
  margin: 0;
  border-radius: 0 0 var(--radius) var(--radius);
  overflow-y: auto;
}
#mastodon.modal-layout .container-alt .form-container .follow-prompt .activity-stream,
.public-layout.modal-layout .container-alt .form-container .follow-prompt .activity-stream,
.modal-layout.modal-layout .container-alt .form-container .follow-prompt .activity-stream {
  margin: 0 !important;
}
#mastodon.modal-layout .container-alt .entry,
.public-layout.modal-layout .container-alt .entry,
.modal-layout.modal-layout .container-alt .entry {
  border-radius: var(--radius) !important;
}
#mastodon.modal-layout #new_remote_follow,
.public-layout.modal-layout #new_remote_follow,
.modal-layout.modal-layout #new_remote_follow {
  position: sticky;
  bottom: 0;
  padding: 20px;
  padding-bottom: 60px;
  background: inherit;
}
@media (max-width: 895px) {
  :root {
    --status-left-padding: 70px;
  }
  #mastodon .columns-area--mobile > .column > div > div,
  .public-layout .columns-area--mobile > .column > div > div,
  .modal-layout .columns-area--mobile > .column > div > div {
    animation: fadeUp 0.4s;
  }
  #mastodon .columns-area--mobile > .column > .column-header__wrapper,
  .public-layout .columns-area--mobile > .column > .column-header__wrapper,
  .modal-layout .columns-area--mobile > .column > .column-header__wrapper {
    display: none;
  }
  #mastodon .columns-area__panels__main,
  .public-layout .columns-area__panels__main,
  .modal-layout .columns-area__panels__main {
    padding: 0 !important;
    max-width: unset;
  }
  #mastodon .columns-area__panels__main .scrollable:not(.scrollable--flex),
  .public-layout .columns-area__panels__main .scrollable:not(.scrollable--flex),
  .modal-layout .columns-area__panels__main .scrollable:not(.scrollable--flex) {
    padding: 0px !important;
    padding-top: 15px !important;
    padding-bottom: 40vh !important;
  }
  #mastodon .columns-area__panels__main .scrollable:not(.scrollable--flex)::before,
  .public-layout .columns-area__panels__main .scrollable:not(.scrollable--flex)::before,
  .modal-layout .columns-area__panels__main .scrollable:not(.scrollable--flex)::before {
    content: "";
    position: absolute;
    inset: 0;
    background-color: inherit;
    z-index: -1;
  }
  #mastodon .columns-area__panels__main .scrollable:not(.scrollable--flex) .account-timeline__header,
  .public-layout .columns-area__panels__main .scrollable:not(.scrollable--flex) .account-timeline__header,
  .modal-layout .columns-area__panels__main .scrollable:not(.scrollable--flex) .account-timeline__header {
    margin: 0px !important;
    margin-top: -15px !important;
  }
  #mastodon .is-composing .columns-area__panels__main,
  .public-layout .is-composing .columns-area__panels__main,
  .modal-layout .is-composing .columns-area__panels__main {
    padding-bottom: 40px !important;
  }
  #mastodon .columns-area__panels__main .scrollable,
  .public-layout .columns-area__panels__main .scrollable,
  .modal-layout .columns-area__panels__main .scrollable,
  #mastodon .columns-area__panels__main .account__header__image,
  .public-layout .columns-area__panels__main .account__header__image,
  .modal-layout .columns-area__panels__main .account__header__image,
  #mastodon .columns-area__panels__main > div,
  .public-layout .columns-area__panels__main > div,
  .modal-layout .columns-area__panels__main > div {
    border-radius: 0 !important;
  }
  #mastodon .explore__links,
  .public-layout .explore__links,
  .modal-layout .explore__links {
    padding: 0 !important;
  }
  #mastodon .status__wrapper,
  .public-layout .status__wrapper,
  .modal-layout .status__wrapper,
  #mastodon .story,
  .public-layout .story,
  .modal-layout .story {
    border-radius: 0 !important;
  }
  #mastodon .status__wrapper::after,
  .public-layout .status__wrapper::after,
  .modal-layout .status__wrapper::after,
  #mastodon .story::after,
  .public-layout .story::after,
  .modal-layout .story::after {
    inset-inline: 0 !important;
  }
  #mastodon .status,
  .public-layout .status,
  .modal-layout .status {
    padding-top: 14px !important;
  }
  #mastodon .status .status__avatar,
  .public-layout .status .status__avatar,
  .modal-layout .status .status__avatar {
    left: 14px !important;
    width: 45px !important;
    height: 45px !important;
    background-size: 45px !important;
  }
  #mastodon .status .status__avatar > div,
  .public-layout .status .status__avatar > div,
  .modal-layout .status .status__avatar > div {
    width: 100% !important;
    height: 100% !important;
    background-size: cover !important;
  }
  #mastodon .status .media-gallery,
  .public-layout .status .media-gallery,
  .modal-layout .status .media-gallery,
  #mastodon .status .video-player,
  .public-layout .status .video-player,
  .modal-layout .status .video-player,
  #mastodon .status .status-card.horizontal.interactive,
  .public-layout .status .status-card.horizontal.interactive,
  .modal-layout .status .status-card.horizontal.interactive,
  #mastodon .status .status-card,
  .public-layout .status .status-card,
  .modal-layout .status .status-card,
  #mastodon .status .audio-player,
  .public-layout .status .audio-player,
  .modal-layout .status .audio-player,
  #mastodon .status .picture-in-picture-placeholder,
  .public-layout .status .picture-in-picture-placeholder,
  .modal-layout .status .picture-in-picture-placeholder {
    margin-bottom: 0 !important;
  }
  #mastodon .detailed-status__wrapper,
  .public-layout .detailed-status__wrapper,
  .modal-layout .detailed-status__wrapper {
    margin: 10px;
    border-radius: var(--radius) !important;
  }
  #mastodon .status__action-bar,
  .public-layout .status__action-bar,
  .modal-layout .status__action-bar {
    margin-inline-start: calc(0px - var(--status-left-padding) + 15px);
    margin-bottom: -10px;
  }
  #mastodon .status__action-bar .icon-button,
  .public-layout .status__action-bar .icon-button,
  .modal-layout .status__action-bar .icon-button {
    flex-grow: 1 !important;
    margin: 0 !important;
    justify-content: center;
    padding: 0.7em !important;
  }
  #mastodon .tabs-bar__wrapper,
  .public-layout .tabs-bar__wrapper,
  .modal-layout .tabs-bar__wrapper {
    position: fixed !important;
    top: unset !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    display: flex;
    flex-direction: column;
    box-shadow: none !important;
    padding: 0;
    background: none;
    border-radius: 0 !important;
    pointer-events: none;
  }
  #mastodon .tabs-bar__wrapper > *,
  .public-layout .tabs-bar__wrapper > *,
  .modal-layout .tabs-bar__wrapper > * {
    pointer-events: all;
  }
  #mastodon .tabs-bar__wrapper .tabs-bar,
  .public-layout .tabs-bar__wrapper .tabs-bar,
  .modal-layout .tabs-bar__wrapper .tabs-bar {
    order: 2;
    margin: 0;
    border: 0;
    border-radius: var(--radius-round) var(--radius-round) 0 0;
    color: #fff !important;
    overflow: visible !important;
    padding-inline: 5px;
    box-shadow: 0 0 24px rgba(0,0,0,0.1);
  }
  .rtl #mastodon .tabs-bar__wrapper .tabs-bar,
  .rtl .public-layout .tabs-bar__wrapper .tabs-bar,
  .rtl .modal-layout .tabs-bar__wrapper .tabs-bar {
    flex-direction: row-reverse;
  }
  #mastodon .tabs-bar__wrapper .tabs-bar a,
  .public-layout .tabs-bar__wrapper .tabs-bar a,
  .modal-layout .tabs-bar__wrapper .tabs-bar a {
    flex-grow: 1 !important;
    flex-basis: unset !important;
    padding-block: 20px;
    position: relative;
    border: 0;
    background: none !important;
    transition: transform 0.4s cubic-bezier(0, 0, 0, 4), filter 0.1s;
  }
  #mastodon .tabs-bar__wrapper .tabs-bar a > i,
  .public-layout .tabs-bar__wrapper .tabs-bar a > i,
  .modal-layout .tabs-bar__wrapper .tabs-bar a > i {
    font-size: 18px;
    filter: opacity(0.7);
    transition: transform 0.2s cubic-bezier(0, 0, 0, 1.2);
  }
  #mastodon .tabs-bar__wrapper .tabs-bar a > i .icon-with-badge__badge,
  .public-layout .tabs-bar__wrapper .tabs-bar a > i .icon-with-badge__badge,
  .modal-layout .tabs-bar__wrapper .tabs-bar a > i .icon-with-badge__badge {
    font-size: 0.5em;
    border-radius: var(--radius-round);
  }
  #mastodon .tabs-bar__wrapper .tabs-bar a::before,
  .public-layout .tabs-bar__wrapper .tabs-bar a::before,
  .modal-layout .tabs-bar__wrapper .tabs-bar a::before {
    content: "";
    position: absolute;
    bottom: -10px;
    width: 5px;
    height: 10px;
    border-radius: 100px;
    background-color: currentColor;
    left: 50%;
    margin-inline-start: -2.5px;
    transition: bottom 0.2s cubic-bezier(0, 0, 0, 4), width 0.2s, margin 0.2s;
  }
  #mastodon .tabs-bar__wrapper .tabs-bar a:active,
  .public-layout .tabs-bar__wrapper .tabs-bar a:active,
  .modal-layout .tabs-bar__wrapper .tabs-bar a:active {
    transform: translateY(5px);
    transition: transform 1s;
  }
  #mastodon .tabs-bar__wrapper .tabs-bar a.active,
  .public-layout .tabs-bar__wrapper .tabs-bar a.active,
  .modal-layout .tabs-bar__wrapper .tabs-bar a.active {
    transform: translateY(-2px);
  }
  #mastodon .tabs-bar__wrapper .tabs-bar a.active i,
  .public-layout .tabs-bar__wrapper .tabs-bar a.active i,
  .modal-layout .tabs-bar__wrapper .tabs-bar a.active i {
    filter: none !important;
  }
  #mastodon .tabs-bar__wrapper .tabs-bar a.active::before,
  .public-layout .tabs-bar__wrapper .tabs-bar a.active::before,
  .modal-layout .tabs-bar__wrapper .tabs-bar a.active::before {
    bottom: -6px;
    width: 10px;
    margin-left: -5px;
  }
  #mastodon .tabs-bar__wrapper #tabs-bar__portal,
  .public-layout .tabs-bar__wrapper #tabs-bar__portal,
  .modal-layout .tabs-bar__wrapper #tabs-bar__portal {
    margin: 10px 15px !important;
    margin-right: auto !important;
    width: max-content !important;
    box-shadow: var(--shadow) !important;
    max-width: calc(100vw - 130px);
    border-radius: var(--radius-round) !important;
    overflow: hidden;
    transition: transform 0.2s;
  }
  #mastodon .tabs-bar__wrapper #tabs-bar__portal > button,
  .public-layout .tabs-bar__wrapper #tabs-bar__portal > button,
  .modal-layout .tabs-bar__wrapper #tabs-bar__portal > button {
    padding-inline: 20px;
  }
  #mastodon .tabs-bar__wrapper #tabs-bar__portal .column-header__wrapper,
  .public-layout .tabs-bar__wrapper #tabs-bar__portal .column-header__wrapper,
  .modal-layout .tabs-bar__wrapper #tabs-bar__portal .column-header__wrapper {
    display: flex;
    flex-direction: column-reverse;
  }
  #mastodon .tabs-bar__wrapper #tabs-bar__portal .column-header,
  .public-layout .tabs-bar__wrapper #tabs-bar__portal .column-header,
  .modal-layout .tabs-bar__wrapper #tabs-bar__portal .column-header {
    border-radius: 0 !important;
  }
  #mastodon .tabs-bar__wrapper #tabs-bar__portal .column-header > button,
  .public-layout .tabs-bar__wrapper #tabs-bar__portal .column-header > button,
  .modal-layout .tabs-bar__wrapper #tabs-bar__portal .column-header > button {
    padding-inline: 15px;
    transform-origin: center !important;
  }
  #mastodon .tabs-bar__wrapper #tabs-bar__portal .column-header__buttons > button,
  .public-layout .tabs-bar__wrapper #tabs-bar__portal .column-header__buttons > button,
  .modal-layout .tabs-bar__wrapper #tabs-bar__portal .column-header__buttons > button {
    padding-inline: 15px;
    transition: padding 0.2s, margin 0.2s;
  }
  #mastodon .tabs-bar__wrapper #tabs-bar__portal .column-header__buttons > button:first-child,
  .public-layout .tabs-bar__wrapper #tabs-bar__portal .column-header__buttons > button:first-child,
  .modal-layout .tabs-bar__wrapper #tabs-bar__portal .column-header__buttons > button:first-child {
    padding-inline-start: 10px;
  }
  #mastodon .tabs-bar__wrapper #tabs-bar__portal .column-header__buttons > button:first-child.active,
  .public-layout .tabs-bar__wrapper #tabs-bar__portal .column-header__buttons > button:first-child.active,
  .modal-layout .tabs-bar__wrapper #tabs-bar__portal .column-header__buttons > button:first-child.active {
    margin-inline-start: 20px;
  }
  #mastodon .tabs-bar__wrapper #tabs-bar__portal .column-header__buttons > button:last-child,
  .public-layout .tabs-bar__wrapper #tabs-bar__portal .column-header__buttons > button:last-child,
  .modal-layout .tabs-bar__wrapper #tabs-bar__portal .column-header__buttons > button:last-child {
    padding-inline-end: 20px;
  }
  #mastodon .tabs-bar__wrapper #tabs-bar__portal .column-header__buttons > button.active,
  .public-layout .tabs-bar__wrapper #tabs-bar__portal .column-header__buttons > button.active,
  .modal-layout .tabs-bar__wrapper #tabs-bar__portal .column-header__buttons > button.active {
    padding-inline: 20px;
  }
  #mastodon .tabs-bar__wrapper #tabs-bar__portal .column-header,
  .public-layout .tabs-bar__wrapper #tabs-bar__portal .column-header,
  .modal-layout .tabs-bar__wrapper #tabs-bar__portal .column-header {
    transition: transform 0.2s;
  }
  #mastodon .tabs-bar__wrapper #tabs-bar__portal .column-header:active,
  .public-layout .tabs-bar__wrapper #tabs-bar__portal .column-header:active,
  .modal-layout .tabs-bar__wrapper #tabs-bar__portal .column-header:active {
    transform: scale(0.98);
  }
  #mastodon .tabs-bar__wrapper #tabs-bar__portal .column-header__collapsible,
  .public-layout .tabs-bar__wrapper #tabs-bar__portal .column-header__collapsible,
  .modal-layout .tabs-bar__wrapper #tabs-bar__portal .column-header__collapsible {
    max-width: calc(100vw - 40px) !important;
    opacity: 1 !important;
    margin-bottom: -1px;
  }
  #mastodon .tabs-bar__wrapper #tabs-bar__portal .column-header__collapsible:not(.collapsed),
  .public-layout .tabs-bar__wrapper #tabs-bar__portal .column-header__collapsible:not(.collapsed),
  .modal-layout .tabs-bar__wrapper #tabs-bar__portal .column-header__collapsible:not(.collapsed) {
    max-height: calc(100vh - 150px);
  }
  .floating-action-button {
    bottom: 70px;
    right: 15px;
    width: 55px;
    height: 55px;
  }
  #mastodon .icon-button:after,
  .public-layout .icon-button:after,
  .modal-layout .icon-button:after {
    content: unset !important;
  }
  #mastodon .notification__filter-bar + .scrollable,
  .public-layout .notification__filter-bar + .scrollable,
  .modal-layout .notification__filter-bar + .scrollable {
    border-radius: var(--radius) var(--radius) 0 0 !important;
  }
  #mastodon .modal-root__modal .status.light,
  .public-layout .modal-root__modal .status.light,
  .modal-layout .modal-root__modal .status.light {
    overflow: hidden !important;
    padding-inline-start: 70px !important;
  }
  #mastodon .columns-area,
  .public-layout .columns-area,
  .modal-layout .columns-area {
    padding-bottom: 0 !important;
    overflow: hidden !important;
  }
  #mastodon .getting-started,
  .public-layout .getting-started,
  .modal-layout .getting-started {
    padding: 20px;
    padding-bottom: 60px;
  }
  #mastodon .getting-started__wrapper,
  .public-layout .getting-started__wrapper,
  .modal-layout .getting-started__wrapper {
    flex-grow: 1;
    overflow: visible !important;
  }
  #mastodon .compose-form__autosuggest-wrapper,
  .public-layout .compose-form__autosuggest-wrapper,
  .modal-layout .compose-form__autosuggest-wrapper {
    border: 1px solid var(--border-color);
  }
  .layout-single-column #mastodon .drawer__inner .navigation-bar {
    padding: 20px;
    padding-bottom: 10px;
  }
  .layout-single-column #mastodon .drawer__inner .compose-form {
    padding-bottom: 100px !important;
  }
  .layout-single-column #mastodon .drawer__inner .compose-form__autosuggest-wrapper {
    border-radius: var(--radius) !important;
    overflow: hidden;
  }
  .layout-single-column #mastodon .drawer__inner .compose-form__modifiers {
    padding-bottom: 50px;
  }
  .layout-single-column #mastodon .drawer__inner .compose-form__publish {
    margin-top: -45px;
    padding-inline: 8px;
  }
  .layout-single-column #mastodon .drawer__inner .compose-form__publish .compose-form__publish-button-wrapper {
    padding-top: 0;
  }
  .layout-single-column #mastodon .drawer__inner .compose-form__buttons-wrapper {
    position: fixed;
    bottom: 70px;
    border-radius: var(--radius-round) !important;
    left: 20px;
    right: 20px;
    padding-block: 0;
    z-index: 2;
  }
  .layout-single-column #mastodon .drawer__inner .compose-form__buttons-wrapper button {
    padding: 7px;
    border-radius: 0 !important;
  }
  .is-composing .modal-root__modal.report-modal {
    width: 100vw;
    height: 100%;
    border-radius: 0;
  }
  .is-composing .modal-root__modal.report-modal .report-modal__comment {
    padding: 20px;
    padding-bottom: 100px;
  }
  .is-composing .modal-root__modal.report-modal .report-modal__comment .button:last-child {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: calc(100% - 40px) !important;
    display: block !important;
    margin: 20px;
  }
  .search-popout {
    max-width: unset !important;
    width: calc(100vw - 70px) !important;
    position: fixed;
    left: 15px;
  }
}
.layout-multiple-columns #mastodon .columns-area {
  overflow: auto hidden !important;
}
.layout-multiple-columns #mastodon .columns-area .column-header,
.layout-multiple-columns #mastodon .columns-area .scrollable,
.layout-multiple-columns #mastodon .columns-area .column-back-button,
.layout-multiple-columns #mastodon .columns-area .account__header__image {
  border-radius: 0 !important;
}
.layout-multiple-columns #mastodon .columns-area .icon-button:after {
  content: unset !important;
}
.layout-multiple-columns #mastodon .columns-area > div {
  border: 0 !important;
  padding: 0 !important;
}
.layout-multiple-columns #mastodon .columns-area > div:not(.drawer):not(:last-child) {
  padding-inline-end: 2px !important;
}
.layout-multiple-columns #mastodon .columns-area > div.column {
  flex-grow: 1;
  max-width: 600px;
}
.layout-multiple-columns #mastodon .columns-area > div:first-child {
  margin-inline-start: auto !important;
}
.layout-multiple-columns #mastodon .columns-area > div:last-child {
  margin-inline-end: auto !important;
}
.layout-multiple-columns #mastodon .columns-area .drawer {
  padding-inline: 10px !important;
  padding-top: 20px !important;
}
.layout-multiple-columns #mastodon .columns-area .drawer .drawer__header {
  border-radius: var(--radius-round);
  margin-inline: 5px;
  overflow: hidden;
  border: 0 !important;
}
.layout-multiple-columns #mastodon .columns-area .drawer .drawer__header a {
  border: 0;
}
.layout-multiple-columns #mastodon .columns-area .drawer .drawer__header a:first-child {
  padding-inline-start: 15px !important;
}
.layout-multiple-columns #mastodon .columns-area .drawer .drawer__header a:last-child {
  padding-inline-end: 15px !important;
}
.layout-multiple-columns #mastodon .columns-area .drawer__inner:not(.darker),
.layout-multiple-columns #mastodon .columns-area .drawer__inner__mastodon {
  background-color: transparent;
  border: 0 !important;
}
.layout-multiple-columns #mastodon .columns-area .drawer__inner.darker {
  border-radius: var(--radius) var(--radius) 0 0;
  margin-inline: 5px;
  width: calc(100% - 10px);
}
.layout-multiple-columns #mastodon .columns-area .getting-started__trends {
  padding: 0px 20px;
}
.modal-root__container {
  animation: bounceIn 0.7s !important;
}
@media (prefers-reduced-motion: no-preference) {
@-moz-keyframes bounceIn {
    0% {
      transform: scale(1.1);
      opacity: 0;
    }
    30% {
      transform: scale(0.99);
      opacity: 1;
    }
    60% {
      transform: scale(1.005);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
}
@-webkit-keyframes bounceIn {
    0% {
      transform: scale(1.1);
      opacity: 0;
    }
    30% {
      transform: scale(0.99);
      opacity: 1;
    }
    60% {
      transform: scale(1.005);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
}
@-o-keyframes bounceIn {
    0% {
      transform: scale(1.1);
      opacity: 0;
    }
    30% {
      transform: scale(0.99);
      opacity: 1;
    }
    60% {
      transform: scale(1.005);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
}
@keyframes bounceIn {
    0% {
      transform: scale(1.1);
      opacity: 0;
    }
    30% {
      transform: scale(0.99);
      opacity: 1;
    }
    60% {
      transform: scale(1.005);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
}
@-moz-keyframes slideUp {
    from {
      transform: translateY(20px);
    }
}
@-webkit-keyframes slideUp {
    from {
      transform: translateY(20px);
    }
}
@-o-keyframes slideUp {
    from {
      transform: translateY(20px);
    }
}
@keyframes slideUp {
    from {
      transform: translateY(20px);
    }
}
@-moz-keyframes slideUpFade {
    from {
      transform: translateY(20px);
      filter: opacity(0);
    }
}
@-webkit-keyframes slideUpFade {
    from {
      transform: translateY(20px);
      filter: opacity(0);
    }
}
@-o-keyframes slideUpFade {
    from {
      transform: translateY(20px);
      filter: opacity(0);
    }
}
@keyframes slideUpFade {
    from {
      transform: translateY(20px);
      filter: opacity(0);
    }
}
@-moz-keyframes slideUpBig {
    from {
      transform: translateY(50vh);
    }
}
@-webkit-keyframes slideUpBig {
    from {
      transform: translateY(50vh);
    }
}
@-o-keyframes slideUpBig {
    from {
      transform: translateY(50vh);
    }
}
@keyframes slideUpBig {
    from {
      transform: translateY(50vh);
    }
}
@-moz-keyframes fadeUp {
    from {
      transform: translateY(10px);
      opacity: 0;
    }
}
@-webkit-keyframes fadeUp {
    from {
      transform: translateY(10px);
      opacity: 0;
    }
}
@-o-keyframes fadeUp {
    from {
      transform: translateY(10px);
      opacity: 0;
    }
}
@keyframes fadeUp {
    from {
      transform: translateY(10px);
      opacity: 0;
    }
}
@-moz-keyframes scaleIn {
    from {
      transform: scale(0.98);
      opacity: 0;
    }
}
@-webkit-keyframes scaleIn {
    from {
      transform: scale(0.98);
      opacity: 0;
    }
}
@-o-keyframes scaleIn {
    from {
      transform: scale(0.98);
      opacity: 0;
    }
}
@keyframes scaleIn {
    from {
      transform: scale(0.98);
      opacity: 0;
    }
}
@-moz-keyframes fadeLeft {
    from {
      transform: translateX(20px) opacity(0);
    }
}
@-webkit-keyframes fadeLeft {
    from {
      transform: translateX(20px) opacity(0);
    }
}
@-o-keyframes fadeLeft {
    from {
      transform: translateX(20px) opacity(0);
    }
}
@keyframes fadeLeft {
    from {
      transform: translateX(20px) opacity(0);
    }
}
}
